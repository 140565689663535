import { Typography, Box } from "@mui/material";
import { Container } from "@mui/system";

import React from "react";
import Heading from "../../components/Heading";

export default function DeliveryOutsourcing() {
  return (
    <div>
      <Heading title="Delivery Executive Outsourcing" />
      <Container>
        <Box sx={{ margin: "4rem 0rem" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            How We Work
          </Typography>
          <Typography textAlign="center">
            At A1QA, we pride ourselves on delivering exceptional delivery
            executive outsourcing services that are efficient, reliable, and
            tailored to meet your unique business needs. Our process is designed
            to ensure smooth operations and customer satisfaction at every step.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            1.Consultation and Assessment:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We begin by sitting down with you to understand your business
            requirements, delivery goals, and challenges. This initial
            consultation helps us determine the scope of your outsourcing needs.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            2. Customized Solutions:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Based on the information gathered during the consultation, our team
            of experts creates a customized outsourcing plan specifically
            designed to address your delivery needs. This plan includes details
            on staffing, vehicle requirements, route optimization, and more.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            3. Recruitment and Training:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We handpick and rigorously train our delivery executives to ensure
            they align with your brand values and customer service expectations.
            Our team is well-versed in safe driving practices and customer
            interaction, delivering a seamless experience to your customers.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            4. Fleet Management:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our well-maintained fleet of vehicles is equipped with GPS and
            tracking technology, enabling real-time monitoring of deliveries.
            This allows us to optimize routes, minimize delivery times, and
            respond swiftly to any unforeseen challenges.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            5. Efficient Route Optimization:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our cutting-edge software continually optimizes delivery routes to
            reduce fuel consumption and improve delivery speed. This results in
            cost savings for you and quicker, more reliable deliveries for your
            customers.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            6. Real-Time Tracking and Notifications:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Customers appreciate transparency. Our real-time tracking system
            allows your customers to monitor the progress of their deliveries
            and receive timely notifications, enhancing their overall
            experience.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            7. 24/7 Customer Support:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our dedicated customer support team is available around the clock to
            address any inquiries, concerns, or issues that may arise during the
            delivery process. We believe in providing timely solutions to ensure
            customer satisfaction.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            8. Performance Evaluation:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We regularly evaluate the performance of our delivery executives and
            the efficiency of our operations. This ongoing assessment allows us
            to make necessary adjustments and continuously improve our service
            quality.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            9. Scalability:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            As your business grows or experiences seasonal fluctuations in
            demand, we have the flexibility to scale our services up or down to
            meet your requirements. We ensure a seamless transition during
            expansion or contraction phases.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            10. Client Feedback:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We value your feedback and actively seek your input to refine our
            services. Your insights help us fine-tune our operations and ensure
            that we are meeting your evolving needs.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            11. Results and Reporting:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We provide detailed performance reports and analytics to keep you
            informed about the impact of our services on your business. This
            data-driven approach allows you to make informed decisions and
            further optimize your delivery operations.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            12. Continuous Improvement:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            At A1QA, we are committed to continuous improvement. We stay updated
            with industry trends and emerging technologies to ensure that we
            remain at the forefront of delivery solutions. Partner with us, and
            experience the difference of a reliable, efficient, and
            customer-focused delivery executive outsourcing service. We are
            dedicated to helping your business succeed by taking care of the
            logistics, so you can focus on what you do best. Contact us today to
            discuss how we can work together to streamline your delivery
            operations and enhance your customer satisfaction.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
