import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

import Heading from "../../../components/Heading";

const EWaste = () => {
  return (
    <div>
      <Heading title="E-Waste Management" />

      <Container>
        <Box mt={10}>
          <Typography variant="h6">
            We empower enterprises to close their material loops. set your
            circularity goals, decode EPR compliances and create robust
            collection programmes with us.
          </Typography>
        </Box>
      </Container>
      <Box backgroundColor="#F4F4F4" mt={10} p={5}>
        <Container sx={{ marginBottom: 10 }}>
          <Box textAlign="center">
            <Typography variant="h4">Our Services</Typography>
          </Box>
          <Box mt={10}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  EPR Design
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Decode, Design And Pilot EPR For Your Industry
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    We leverage our knowledge capital and industry expertise to
                    design programs that are sustainable, inclusive, and
                    scalable. We aim to demonstrate radical transparency with
                    our partners through voluntary data sharing and knowledge
                    development activities that further the collective
                    advancement of the industry.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  EPR Compliance Management
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Meet Compliances. Create Impact .
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Our EPR programs cut across sectors to strengthen the value
                    chain of multiple waste streams. these programs are designed
                    to deliver on sector-specific compliances while leveraging
                    our engagements across the value chain. Our processes are
                    end-to-end enabled by technology and monitored by multi-step
                    audits.
                  </Typography>
                  <Typography variant="subtitle1" mt={1}>
                    Gain a head-start on your circular economy transition by
                    identifying the right fit EPR programs for you.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  Awareness Programmes
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Consume Better. Consume Wiser.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    a1qa’s awareness programs surpass the industry norms and
                    pure compliance benchmarks. We ensure that our awareness
                    programs include sustained engagement and clear
                    call-to-actions for consumers who want to responsibly
                    recycle their waste.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Our flagship programs for schools and the coveted
                    International E-Waste Day are implemented year after year to
                    drive behaviour change.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  Collection Infrastructure
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Maximum Collection. Maximum Reach.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    a1qa has collection centers and collection channels in 30+
                    Indian states and UTs. Our employees in each state are
                    equipped with the infrastructure, expertise, and market
                    in-roads to ensure that waste collected by a1qa is diverse
                    and closer to the source.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Through a mix of collection centers, door-to-door pick-ups,
                    and consumer engagements, our producer partners can showcase
                    localized impact to State Pollution Control Boards, while
                    supporting the development of diversified collect
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  Ecosystem Development
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Help Build The Circular Economy Ecosystem
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Through strategic projects and engagements, a1qa has brought
                    together disparate players into the conversation about
                    circular economy and EPR. Ecosystem enablers and alliances
                    catalyze innovation, incubate creative solutions, and offer
                    the space for experimentation and rapid learning.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Projects typically focus on capacity building, awareness
                    program delivery, development of standards, driving
                    intersectional approaches to waste, and convening
                    organizations that advocate for ethical and sustainable EPR
                    models.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  Technology Platform
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Grassroot + Expertise + Technology = Impact
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Through our inception, a1qa has utilized technology to
                    complement on-ground operations. By combining our expertise
                    on EPR with technology tools like Microsoft Azure, we
                    identified key processes that can be automated, the insights
                    that can be generated, and the impact that can be measured
                    through the a1qa App.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    The App is waste stream agnostic and acts as a
                    multi-stakeholder platform while providing key data sets for
                    decision-makers. We extend this service to global agencies
                    working to advance circular economy in their countries.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  Recycling
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">Close The Loop.</Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Through its partnership with the German Agency for
                    International Cooperation (GIZ), a1qa has established a
                    model e-waste dismantling unit and a material recovery
                    facility.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    The goal?
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    To determine benchmarks for legitimate operations and
                    financial sustainability of such units, while creating a
                    glasshouse-effect for the sector at large. These units are a
                    critical part of closing material loops by creating
                    end-to-end auditable chains for secondary materials. The
                    project especially caters to the formalization of the
                    informal sector by transitioning them to green jobs, as well
                    as providing multi-material compliance management to bulk
                    consumers.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  Secondary Materials
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Produce Better. With Less.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    a1qa can help accelerate your race to net-zero by procuring
                    secondary materials that come from auditable and clean
                    supply chains.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    From fair working conditions to impact programs with various
                    actors- each aspect at every stage of the material movement
                    is tracked, monitored, and evaluated to ensure that brands
                    can procure raw materials which are good for people and the
                    planet.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h5" p={2} fontWeight="bold">
                  Consulting
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1}>
                  <Typography variant="h6">
                    Going Beyond What’s Possible.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    a1qa implements projects which serve the larger goals of
                    sustainable development and circular economy. Through
                    research and consulting outputs, we drive progressive trends
                    in the sector and keep raising the bar.
                  </Typography>
                  <Typography variant="subtitle1" mt={2}>
                    Our work is sector agnostic and extends to all members of
                    the value chain which have a stake in enabling the circular
                    economy.
                  </Typography>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default EWaste;
