import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
// import logo from "../../assets/images/logo1.png";

// import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { Link } from "react-router-dom";
// const media = [
//   {
//     icon: <FacebookOutlinedIcon />,
//     link: "#",
//   },
//   {
//     icon: <LinkedInIcon />,
//     link: "#",
//   },
//   {
//     icon: <TwitterIcon />,
//     link: "#",
//   },
// ];
const company = [
  {
    name: "About Us ",
    path: "/about-us",
  },
  {
    name: "Contact Us",
    path: "/contact-us",
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy Quality ",
  },
];
export default function Footer() {
  const small = useMediaQuery("(max-width:960px)");
  return (
    <div>
      <Box sx={{ background: "black", color: "white", mt: "1rem" }}>
        <Container
          style={{
            diaplay: "flex",
            textAlign: small ? "center" : "",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            container
            spacing={2}
            style={{ padding: "3rem 0rem 2rem 0rem" }}
          >
            {/* <Grid item md={3} sm={12} xs={12}>
              <img
                src={logo}
                alt="logo"
                style={{
                  width: small ? "200px" : "",
                  height: small ? "50px" : "",
                }}
              />
            </Grid> */}
            <Grid item md={4} sm={12} xs={12}>
              <Box>
                <Typography style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  India
                </Typography>
                <Typography style={{ fontSize: small ? "0.8rem" : "1rem" }}>
                  A1QA , HD-192 WeWork Lattitude , 9th floor , RMZ Lattitude
                  commercial , Bellary Road , Hebbal , Near Godrej Apt ,
                  Bengaluru , Karnataka 560024
                </Typography>
              </Box>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              {/* <Box>
                <Typography style={{ fontSize: "1rem", fontWeight: "bold" }}>
                  Follow us
                </Typography>
                <Box>
                  {media.map((item, i) => (
                    <Link
                      key={i}
                      style={{
                        textDecoration: "none",
                        color: "black",
                        marginRight: "1rem",
                      }}
                      to={item.link}
                    >
                      {item.icon}
                    </Link>
                  ))}
                </Box>
              </Box> */}
            </Grid>
          </Grid>
          <Box style={{ padding: "1rem 0rem" }}>
            <Grid container spacing={2}>
              <Grid item md={6} sm={12} xs={12}>
                <Typography style={{ fontSize: "0.8rem" }}>
                  © a1qa software testing company, 2023. All rights reserved.
                </Typography>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <Box sx={{ display: { md: "flex" } }}>
                  {company.map((link, j) => (
                    <Link
                      key={j}
                      to={link.path}
                      style={{ textDecoration: "none", padding: "0rem 0.5rem" }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: "white",
                          fontSize: "0.8rem",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {link.name}
                      </Typography>
                    </Link>
                  ))}
                  {/* <Typography
                    style={{ fontSize: "0.8rem", padding: "0rem 0.5rem" }}
                  >
                    Privacy Policy Quality
                  </Typography> */}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
