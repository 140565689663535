import { Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Heading from "../../../components/Heading";

export default function Delivery() {
  return (
    <div>
      <Heading title="Delivery Executives Outsourcing" />
      <Container>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Introduction:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            At A1QA, we understand the challenges businesses face in managing
            their delivery operations efficiently. Whether you run a restaurant,
            e-commerce platform, or any other business that relies on timely and
            reliable deliveries, finding and managing a dedicated team of
            delivery executives can be a daunting task. That's where we come in.
            Why Outsource Delivery Executives? Outsourcing your delivery
            executives can bring a multitude of benefits to your business.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Cost-Effective Solutions:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Outsourcing allows you to reduce labor and operational costs, as you
            won't need to hire full-time delivery staff or invest in vehicle
            maintenance.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Scalability:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Flexibility is key in today's dynamic business environment. With
            outsourced delivery services, you can easily scale up or down to
            meet fluctuating demand without the hassle of recruitment or
            layoffs.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Focus on Your Core Business:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            By letting professionals handle your delivery operations, you can
            concentrate on what you do best – growing your business and serving
            your customers.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Expertise and Efficiency:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Outsourcing partners often have a wealth of experience in optimizing
            delivery routes and schedules, ensuring quicker deliveries and
            improved customer satisfaction.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Our Delivery Executive Outsourcing Services:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            A1QA offers a comprehensive range of services tailored to meet your
            specific needs:
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Professional Delivery Teams:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We provide experienced and trained delivery executives who are
            dedicated to ensuring your products reach your customers safely and
            on time.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Fleet Management:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our fleet of vehicles is well-maintained, equipped with tracking
            technology, and ready to support your delivery requirements.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Route Optimization:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We use cutting-edge technology to optimize delivery routes, reducing
            fuel consumption and delivery times.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Real-Time Tracking:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Keep your customers informed with real-time delivery tracking and
            notifications.
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Customer Support:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our customer support team is available around the clock to address
            any delivery-related inquiries or concerns.{" "}
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem " }}>
          <Typography
            sx={{ fontWeight: "bold", textAlign: "center" }}
            variant="h4"
          >
            Why Choose A1QA ?
          </Typography>
        </Box>
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Proven Track Record:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We have a history of successfully supporting businesses of all sizes
            in improving their delivery operations.{" "}
          </Typography>
        </Box>{" "}
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Custom Solutions:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our services are flexible and can be tailored to fit your unique
            business needs.
          </Typography>
        </Box>{" "}
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Technology-Driven:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We leverage the latest technology to enhance the efficiency and
            transparency of our services.{" "}
          </Typography>
        </Box>{" "}
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Dedicated Team:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our commitment to customer satisfaction means you have a partner you
            can trust.{" "}
          </Typography>
        </Box>{" "}
        <Box sx={{ margin: "3rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Get Started Today:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Outsourcing your delivery executives with A1QA is a smart choice
            that can lead to cost savings, improved service, and increased
            customer loyalty. Let us handle the logistics while you focus on
            growing your business. Contact us today to discuss your requirements
            and explore how we can work together to take your delivery
            operations to the next level.{" "}
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
