import { Grid, Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Heading from "../../components/Heading";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PersonalVideoSharpIcon from "@mui/icons-material/PersonalVideoSharp";
import ColorizeIcon from "@mui/icons-material/Colorize";
import StarsIcon from "@mui/icons-material/Stars";

import ContactOurTeam from "../../components/ContactOurTeam";

const cards = [
  {
    title: "Fixed price",
    subtitle:
      "Well-suited for small and medium-size projects with established methodologies and a stable set of requirements.",
    point1: "Project requirements are pre-defined",
    point2: "Project costs and duration are specified",
    point3:
      "Re-estimation of costs and time is possible in case changes are required",
    title2: "Billed in several installments",
  },
  {
    title: "Time & materials",
    subtitle:
      "Appropriate for projects without definitive requirements where risks cannot be predicted beforehand.",
    point1: "Requirements are not yet outlined",
    point2: "Cost is based on actual person-hours and can be adjusted flexibly",
    point3: "Any project changes are acceptable",
    title2: "Billed per work completed in the billing period",
  },
  {
    title: "Dedicated team",
    subtitle:
      "Fit for long-term projects with unidentified requirements, or several concurrent projects that may need resource relocation.",
    point1: "Only basic project requirements are specified",
    point2: "Frequent project amendments are possible",
    point3:
      "Team knowledge retention and full control over the team operation are ensured",
    title2: "Billed in defined monthly payments",
  },
];

const benifitsData = [
  {
    title: "Full commitment to your needs and expectations",
    subtitle:
      "Our QA specialists dive into every business goal and select the most suitable QA solution with minimum efforts and resources spent.",
    icon: <CheckCircleOutlineIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "Highly flexible, clear, and transparent operations",
    subtitle:
      "Our QA team easily adapts and integrates at any SDLC stage, providing professional QA support along with smart team scalability",
    icon: <PersonalVideoSharpIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "A comprehensive suite of software testing services",
    subtitle:
      " a1qa provides a one-stop shop for full-cycle QA and quality engineering, ensuring high software quality.",
    icon: <ColorizeIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "In-depth cross-product and industry-specific knowledge",
    subtitle:
      "Our skilled tutors provide 100+ courses at our in-house QA Academy, while our QA specialists constantly deepen their expertise gained in 1,500 successfully delivered projects.",
    icon: <StarsIcon sx={{ color: "blue" }} fontSize="large" />,
  },
];

export default function Howwework() {
  return (
    <div>
      <Heading
        title="
How we work"
        subtitle="a1qa has created an approach to efficient customer engagement based on our multi-year experience, customer-first mindset, and mature QA processes"
      />
      <Container>
        <Box m="4rem 0rem">
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "3rem", sm: "2rem", xs: "1rem" },
              lineHeight: "56px",
              marginBottom: "4rem",
              textAlign: "center",
            }}
          >
            Benefits of partnering with an experienced QA provider
          </Typography>
          <Grid container spacing={5}>
            {benifitsData.map((item, i) => (
              <Grid item key={i} md={6} sm={12} ld={6} xs={12}>
                <Box display="flex">
                  <Box
                    sx={{
                      padding: "0.5rem",
                      background: "#fafafa",
                      height: "50px",
                      marginRight: "1rem",
                    }}
                  >
                    {item.icon}
                  </Box>
                  <Box>
                    <Typography variant="h5" fontWeight="600" lineHeight="26px">
                      {item.title}
                    </Typography>
                    <Typography variant="subtitle1" mt={1}>
                      {item.subtitle}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ margin: "5rem 0rem " }}>
          <Typography
            variant="h2"
            sx={{ fontSize: { md: "3rem", sm: "2rem", xs: "1rem" } }}
            fontWeight="400"
            textAlign="center"
          >
            Choose your engagement model
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {cards.map((item, i) => (
            <Grid item md={4} lg={4} sm={12} xs={12}>
              <Paper
                sx={{
                  //   minHeight: 400,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
                elevation={3}
              >
                <Box sx={{ padding: 3, mb: "2rem" }}>
                  <Typography variant="h5" fontWeight="bold">
                    {item.title}
                  </Typography>

                  <Typography mt={2}>{item.subtitle}</Typography>
                  <Typography mt={1}> &bull; {item.point1}</Typography>
                  <Typography mt={1}> &bull; {item.point2}</Typography>
                  <Typography mt={1}> &bull; {item.point3}</Typography>
                </Box>

                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ padding: 3, marginTop: "auto", height: "100px" }}
                  bgcolor="#fafafa"
                >
                  {item.title2}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Let’s schedule a call with our tech expert to discuss the hindrances to your solution’s quality."
        btn="Drop us a line"
      />

      <Box
        sx={{
          margin: "5rem 0rem ",
        }}
      >
        <Container>
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "3rem", sm: "2rem", xs: "1rem" },
              marginBottom: "2rem",
            }}
            fontWeight="400"
            textAlign="center"
          >
            Cooperating with a1qa is easy
          </Typography>
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { md: "2rem", sm: "2rem", xs: "1rem" },
              }}
              fontWeight="900"
            >
              We sign NDAs
            </Typography>
            <Typography sx={{ maxWidth: { sm: "100%", md: "50%" } }}>
              Our testing specialists commit to keeping all exchanged data
              confidential and not disclosing it to any third parties.
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { md: "2rem", sm: "2rem", xs: "1rem" },
              }}
              fontWeight="900"
            >
              We start with analysis
            </Typography>
            <Typography sx={{ maxWidth: { sm: "100%", md: "50%" } }}>
              Our QA professionals define the functionality to be tested and
              outline required QA activities to perform.
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { md: "2rem", sm: "2rem", xs: "1rem" },
              }}
              fontWeight="900"
            >
              We deliver software testing
            </Typography>
            <Typography sx={{ maxWidth: { sm: "100%", md: "50%" } }}>
              As our QA team carries out QA services, they deliver detailed
              daily status reports to keep the client informed.
            </Typography>
          </Box>
          <Box sx={{ marginBottom: "1rem" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: { md: "2rem", sm: "2rem", xs: "1rem" },
              }}
              fontWeight="900"
            >
              We report the results
            </Typography>
            <Typography sx={{ maxWidth: { sm: "100%", md: "50%" } }}>
              We demonstrate complete test results with the detailed list of
              bugs and their severity, full reproduction paths, and
              recommendations on software quality improvement.
            </Typography>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
