import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../components/Heading";

export default function Outsoucingsolution() {
  return (
    <div>
      <Heading
        title="Outsourcing and Staffing Solutions "
        subtitle="Running a small to mid-sized business comes with its unique set of challenges, especially when it comes to acquiring and managing a diverse range of professionals. Whether you require Test Engineers, Technicians, Quality Assurance Experts, or Professional Field Service Executives, finding the right talent can be a time-consuming and resource-intensive process. This is where outsourcing and staffing services can play a pivotal role in helping your business thrive."
      />
      <Container>
        <Box sx={{ margin: "4rem 0rem" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Why Consider Outsourcing and Staffing?
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Cost-Efficiency:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Outsourcing and staffing solutions can significantly reduce your
            recruitment and HR-related expenses. You can allocate your budget
            more efficiently while still acquiring top-tier professionals.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Access to Talent:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Tap into a vast pool of talent with diverse skills and experience
            levels. Whether you need specialized professionals for short-term
            projects or long-term team members, outsourcing and staffing
            agencies can match your needs.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Flexibility:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Small to mid-sized businesses often experience fluctuations in
            demand. Outsourcing and staffing solutions allow you to scale your
            workforce up or down quickly, ensuring optimal resource allocation.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Expertise:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Rely on agencies with industry-specific expertise in recruiting and
            managing professionals. They understand your sector's nuances and
            can identify candidates who align with your business goals.
          </Typography>
        </Box>
        <Box sx={{ margin: "4rem 0rem" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Our Comprehensive Services for Your Business
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            1. Test Engineers:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Ensure the quality and reliability of your software and systems with
            our dedicated Test Engineers. We provide experienced professionals
            who can design, execute, and report on comprehensive testing
            strategies, helping you release robust products and solutions.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            2. Technicians:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            From IT support to equipment maintenance, our Technicians are
            skilled in diagnosing and resolving technical issues promptly.
            Maintain the functionality of your equipment and systems without the
            hassle of full-time hires.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            3. Quality Assurance Experts:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Quality Assurance is critical in today's competitive market. Our
            Quality Assurance Experts meticulously review and enhance your
            products or services, guaranteeing customer satisfaction and
            compliance with industry standards.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            4. Professional Field Service Executives:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            When it comes to on-site service, our Field Service Executives are
            your trusted partners. They excel in delivering exceptional customer
            experiences and resolving issues efficiently, contributing to
            positive brand perception.
          </Typography>
        </Box>
        <Box sx={{ margin: "4rem 0rem" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            How We Work
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Consultation:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We start by understanding your business objectives, workforce
            requirements, and specific roles you need to fill.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Talent Sourcing:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our dedicated teams leverage extensive networks and industry
            knowledge to identify the most suitable candidates for your needs.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Screening and Selection:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We conduct rigorous interviews, skills assessments, and background
            checks to ensure candidates meet your quality standards.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Onboarding:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We handle all administrative processes, including contracts,
            payroll, and benefits, simplifying the hiring process for your
            business.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Ongoing Support:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our commitment doesn't end with placement. We provide ongoing
            support to both clients and professionals to ensure seamless
            collaboration.
          </Typography>
        </Box>
        <Box sx={{ margin: "4rem 0rem" }}>
          <Typography
            variant="h4"
            style={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            Why Choose A1QA?
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Industry Expertise:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We have a proven track record in providing outsourcing and staffing
            solutions across various professional fields.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Customized Solutions:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Our services are flexible and tailored to the unique needs of small
            to mid-sized businesses.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Quality Assurance:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            We are committed to delivering high-quality professionals who align
            with your business goals.
          </Typography>
        </Box>
        <Box sx={{ margin: "2rem 0rem" }}>
          <Typography
            sx={{ fontWeight: "bold", display: "inline" }}
            variant="h5"
          >
            Scalability:{" "}
          </Typography>
          <Typography sx={{ display: "inline" }}>
            Whether you need a single Test Engineer or an entire team of Field
            Service Executives, we adapt to your changing requirements.
          </Typography>
          <Typography sx={{ marginTop: "1rem" }}>
            Elevate your small to mid-sized business with our outsourcing and
            staffing solutions. Focus on your core operations, while we provide
            you with the skilled professionals you need to succeed. Contact us
            today to explore how our services can drive your business forward.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}
