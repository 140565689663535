import { Box, Container, Typography } from "@mui/material";
import React from "react";

import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";

const Field = () => {
  return (
    <div>
      <Heading title="Field Testing" />

      <Container>
        <Box mt={10}>
          <Typography variant="h6">
            Before beginning field testing, the tester should be equipped with
            right tools and techniques and know the process. The tester should
            well plan what he is testing, what is the procedure, input/output
            data and so on. In a1qa, we design our strategy, plan and standard
            approach as per customer requirements and ensure maintaining balance
            between manual and automation testing. We make pre plans before
            performing field testing so that it can be completed without any
            problem. First, we need right resource who is able to face complex
            testing task and executed it without wasting time in the field.
            Second is tester need right tools i.e. Required hardware, software
            to perform, SIM cards and ready cab to perform field testing. Third
            and last one is tester should know the precious technique to execute
            the field test. All these things are available with us and We
            perform the critical field tests and provide the necessary analytics
            and issue resolution you need to get your device to market. a1qa
            always focus on R&D test plans and operator specific test plan which
            cover 2G,3G,4G,5G mobile device performance on live operator
            networks. We already build our lab and automated tool for customized
            test plans and upcoming 5G network. a1qa offer best and secure work
            with a well-trained team in domestic and as well as internationally.
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h6">
            Device would not exist without hardware, software and operating
            systems. So, we also need to think about how to plan the Field
            Tests- a1qa follows the below mentioned modes to ensure Field Test
            success:
          </Typography>
        </Box>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">Stationary Test</Typography>
        </Box>
        <Box mt={7}>
          <Typography variant="h6">
            a1qa’s stationary testing focuses on delivering bug free and high
            performing devices to enterprises. Our stationary testing services
            ensure that the devices you build have ample regression coverage,
            accelerate time to market and deliver superior customer experiences
            across any devices. Companies working with a1qa have been able to
            achieve higher score on customer experiences indexes, Lower cost
            improve time to market. Our stationary test includes two parts.
          </Typography>
        </Box>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Set up a flexible dedicated team ready to solve your QA tasks from end to end."
        btn="Contact us"
      />

      <Container>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Manual Test</Typography>
        </Box>
        <Box mt={7}>
          <Typography variant="h6">
            Device testing is a complex process. Sometimes, real human
            experience can deliver the results you want. Our QA teams use manual
            testing to ensure that the final product really works as intended.
            With a specific role to play, manual testing is used to explore
            use-cases that may not be all that obvious. There will be a
            specified criterion that each test case must meet. There will also
            be an exception that all the test cases should work properly inside
            the device during the test plan. Each test case will run manually.
            Any test case that fails to meet the criteria is rejected. A bug
            report is filled for each rejected plan. ​
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h6">
            Manual testing is mainly based on human inputs and analysis without
            any help of automated tools. This proposal is user centric and
            focuses on exploratory ways of monitoring whether a mobile meets
            user requirements and expectations. All test cases executed by the
            tester manually according to the end user’s perspective. It ensures
            whether the application is working, as mentioned in the requirement
            document or not. Test cases are planned and implemented to complete
            almost 100% of the software application. Test case reports are also
            made manually. Manual testing is a vital element in software
            building because automated testing can’t cover everything. Manual
            testing finds and solves more usability issues than automated
            testing because it allows the tester to be flexible during the test,
            so they can try different options. The difference between expected
            output and output, given by the software, is defined as a defect.
            The developer fixed the defects and handed it to the tester for
            retesting.
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h6">
            Manual testing is mandatory for every newly developed software
            before automated testing. This testing requires great efforts and
            time, but it gives the surety of bug-free software. Manual Testing
            requires knowledge of manual testing techniques, but not of any
            automated testing tool.
          </Typography>
        </Box>
      </Container>

      <Container>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">Automation test</Typography>
        </Box>
        <Box mt={7}>
          <Typography variant="h6">
            a1qa's has its own in house Automation Test tool and has a
            specialized team to continually develop/maintain the tool as per
            changing needs of customers and new introductions in technology.
            There are some cases where manual testing is the better option-
            however, some QA tests are either too tedious or too complex for
            human testers. There’s where a1qa’s automated testing tool- MATT
            comes into the effect to make sure things are executed continuously
            and alongside manual tests, to assure quality and release better
            products, faster.
          </Typography>
        </Box>

        <Box textAlign="center" mt={10}>
          <Typography variant="h4">Mobility or Drive Testing</Typography>
        </Box>
        <Box mt={7}>
          <Typography variant="h6">
            Combining the strengths of a1qa’s mobility solution we are today a
            team of 300+ mobility professionals serving more than 30 customers
            in several countries around the globe, this makes us the leading
            mobile solutions provider from Asia and one of the best in the
            global markets. a1qa’s dedicated team of Field Test engineers
            provide end-to-end Mobility/ drive test solutions and services to
            telecom operators, device manufacturers (ODMs/OEMs) and enterprises
            around the world. Our expertise in Device Testing, D2C (Direct to
            Consumer) & Platform Services, and Enterprise Solutions help our
            clients leverage the crucial advantage of a cutting-edge Mobility
            ecosystem. We are the award winning mobility solutions. Our Mobility
            offerings bring together content, technology and device expertise to
            ensure an enhanced digital experience to the end-users.
          </Typography>
        </Box>
      </Container>
    </div>
  );
};

export default Field;
