import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import TimelineIcon from "@mui/icons-material/Timeline";
import JoinRightIcon from "@mui/icons-material/JoinRight";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import AssessmentIcon from "@mui/icons-material/Assessment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import QuizIcon from "@mui/icons-material/Quiz";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AnchorIcon from "@mui/icons-material/Anchor";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";

import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";

const LifeCycle = () => {
  return (
    <div>
      <Heading
        title="Software lifecycle QA"
        subtitle="An all-embracing service package to deliver you the best of QA"
      />
      <Container>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">
            Reach your business objectives with full-cycle testing
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              With our independent quality assessment, a1qa helps your team
              focus on the programming side and reduce the development time by
              spotting bugs and defects that no one could foresee.
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper sx={{ padding: 3, minHeight: 310 }} elevation={3}>
              <Box mb={2}>
                <TimelineIcon sx={{ color: "blue", fontSize: 40 }} />
              </Box>
              <Typography variant="h5" fontWeight="bold">
                End-to-end test coverage
              </Typography>

              <Typography mt={2}>
                Throughout lifecycle QA, we conduct all needed types of testing:
                functional, performance, security, GUI, usability, database,
                compatibility, accessibility, localization, and more to ensure
                an optimized test coverage.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper sx={{ padding: 3 }} elevation={3}>
              <Box mb={2}>
                <JoinRightIcon sx={{ color: "blue", fontSize: 40 }} />
              </Box>
              <Typography variant="h5" fontWeight="bold">
                Complete process transparency
              </Typography>
              <Typography mt={2}>
                We provide complete transparency of our software testing
                activities and take full responsibility for them. Also, we
                seamlessly integrate with your business and development
                processes for full testing alignment.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper sx={{ padding: 3, minHeight: 310 }} elevation={3}>
              <Box mb={2}>
                <SignalCellularAltIcon sx={{ color: "blue", fontSize: 40 }} />
              </Box>
              <Typography variant="h5" fontWeight="bold">
                Established KPIs
              </Typography>
              <Typography mt={2}>
                We always establish the most important QA KPIs and keep you
                informed about their status that you can track our progress with
                the testing tasks and check if our intermediate results align
                with your goals.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Box backgroundColor="#FAFAFA" mt={10} p={3}>
        <Container>
          <Box textAlign="center">
            <Typography variant="h4">
              One testing ecosystem for all SDLC stages
            </Typography>
            <Container>
              <Typography variant="subtitle1" mt={3}>
                Our team of QA professionals starts in the early stages of the
                software development to prevent costly errors before they occur
                and let no critical issues leak to production and finishes when
                the stabilization process is completed.
              </Typography>
            </Container>
          </Box>

          <Box mt={5}>
            <Paper sx={{ padding: 3 }} elevation={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    1. Documenting
                  </Typography>
                  <Typography>&bull; Documentation testing</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    2. Requirements
                  </Typography>
                  <Typography>&bull; Requirements testing</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>3. Design</Typography>
                  <Typography>&bull; Prototype testing</Typography>
                  <Typography>&bull; Usability testing</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    4. Development
                  </Typography>
                  <Typography>&bull; Unit testing</Typography>
                  <Typography>&bull; Integration testing</Typography>
                  <Typography>&bull; Test testing</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    5. Stabilization
                  </Typography>
                  <Typography>&bull; Intermediary testing</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    6. Delivery/release
                  </Typography>
                  <Typography>&bull; Reproducing issues</Typography>
                  <Typography>&bull; Identifying root causes</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    7. Production
                  </Typography>
                  <Typography>&bull; Acceptance testing</Typography>
                  <Typography>&bull; Migration testing</Typography>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <Typography sx={{ fontWeight: "bold" }}>
                    8. Regression testing
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Container>
      </Box>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Deliver a high-quality software product quicker with end-to-end QA."
        btn="Get in touch "
      />
      <Container>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">Already have a QA team?</Typography>
        </Box>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                padding: 5,
                backgroundColor: "black",
                color: "white",
                height: "auto",
              }}
            >
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  Benefit from our 20+ years of industry and technology
                  expertise accumulated in our proprietary:
                </Typography>
                <Box display="flex">
                  <Box mr={3}>
                    <Typography variant="subtitle1" mt={1}>
                      Centers of Excellence (CoE)
                    </Typography>
                    <Typography variant="subtitle1">Services:</Typography>
                    <Typography variant="subtitle1">
                      &bull; Security testing
                    </Typography>
                    <Typography variant="subtitle1">
                      &bull; Mobile application testing
                    </Typography>
                    <Typography variant="subtitle1">
                      &bull; QA consulting, and more
                    </Typography>

                    <Typography variant="subtitle1" mt={1}>
                      Industries:
                    </Typography>
                    <Typography variant="subtitle1">&bull; Retail</Typography>
                    <Typography variant="subtitle1">&bull; Telecom</Typography>
                    <Typography variant="subtitle1">&bull; BFSI</Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" mt={1}>
                      R&Ds
                    </Typography>
                    <Typography variant="subtitle1">
                      &bull; Test automation
                    </Typography>
                    <Typography variant="subtitle1">
                      &bull; Performance testing
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                padding: 5,
                backgroundColor: "black",
                color: "white",
                height: 412,
              }}
            >
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  Advance with our well-defined testing approach comprising the
                  detailed definition and confirmation of:
                </Typography>
                <Box mt={2}>
                  <Typography variant="subtitle1">
                    &bull; Project stages and goals
                  </Typography>
                  <Typography variant="subtitle1">
                    &bull; Testing types and coverage
                  </Typography>
                  <Typography variant="subtitle1">
                    &bull; Testing documentation
                  </Typography>
                  <Typography variant="subtitle1">
                    &bull; Proven testing methods
                  </Typography>
                  <Typography variant="subtitle1">
                    &bull; Project success criteria and KPIs
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            A-to-Z reporting on every stage of software development
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              With our dedicated software testing practice, a1qa is competent in
              providing detailed reporting at each development stage or
              on-demand.
            </Typography>
            <Typography variant="subtitle1" mt={3}>
              Capable of showing high productivity from the get-go, we deliver
              clear and complete testing results, keeping you informed about the
              product quality status.
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "#19152F",
                color: "white",
              }}
            >
              <Box mb={2}>
                <AssessmentIcon sx={{ color: "white", fontSize: 40 }} />
              </Box>
              <Typography variant="subtitle1">
                &bull; Quality reports
              </Typography>
              <Typography variant="subtitle1">
                &bull; Build/release reports
              </Typography>
              <Typography variant="subtitle1">
                &bull; Iteration results
              </Typography>
              <Typography variant="subtitle1">
                &bull; Delivery reports
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "#19152F",
                color: "white",
                minHeight: 225,
              }}
            >
              <Box mb={2}>
                <FileCopyIcon sx={{ color: "white", fontSize: 40 }} />
              </Box>
              <Typography variant="subtitle1">Type-specific reports</Typography>
              <Typography variant="subtitle1">
                (performance, accessibility, compatibility, usability, etc.)
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "#19152F",
                color: "white",
              }}
            >
              <Box mb={2}>
                <QuizIcon sx={{ color: "white", fontSize: 40 }} />
              </Box>
              <Typography variant="subtitle1">
                &bull; Test status reports
              </Typography>
              <Typography variant="subtitle1">
                &bull; Daily status reports
              </Typography>
              <Typography variant="subtitle1">
                &bull; Test efforts reports
              </Typography>
              <Typography variant="subtitle1">
                &bull; Monthly efforts reports
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Why choose lifecycle QA by a1qa?</Typography>
        </Box>
        <Grid container spacing={5} mt={5}>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={3} mt={2}>
              <MilitaryTechIcon sx={{ color: "blue", fontSize: 50 }} />
            </Box>
            <Typography variant="h6">Years of evolving QA expertise</Typography>
            <Typography variant="subtitle1">
              With a 20-year track record in SQA, we have gathered
              technology-centric QA expertise across a variety of industries
              (IT, BFSI, telecom, retail, eHealth, and more).
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={3} mt={2}>
              <AnchorIcon sx={{ color: "blue", fontSize: 50 }} />
            </Box>
            <Typography variant="h6">A pool of motivated QA Jedi</Typography>
            <Typography variant="subtitle1">
              In our in-house QA Academy, skilled tutors work tirelessly to
              conduct exclusive training for QA engineers and managers to update
              their skillsets in specific industries and technologies.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2} mt={1}>
              <HeadsetMicIcon sx={{ color: "blue", fontSize: 50 }} />
            </Box>
            <Typography variant="h6">
              Complete QA support throughout SDLC
            </Typography>
            <Typography variant="subtitle1">
              Excelling at the complete range of testing services, from
              functional testing to Agile QA, we wisely pick the best options
              for you depending on your software type, product specifics, and
              business needs to be met.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LifeCycle;
