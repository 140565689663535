import { Box, Grid, Typography, Paper } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Heading from "../../components/Heading";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SupportAgentSharpIcon from "@mui/icons-material/SupportAgentSharp";
import phoneCharging from "../../assets/images/phones charging.webp";

import Iot from "../../assets/images/IoT-devices-1.webp";
import solution from "../../assets/images/solution.png";

import alex from "../../assets/images/alex-mNJ7c7-XCZQ-unsplash.webp";
import { useNavigate } from "react-router-dom";

const cards = [
  {
    backImg: phoneCharging,
    name: "Mobile Device Testing ",
    path: "/mobile-testing",
  },

  {
    backImg: Iot,
    name: "IOT Testing",
    path: "/iot-testing",
  },
  // {
  //   backImg: iphone,
  //   name: "IT Assets On Rent",
  //   path: "/it-assets",
  // },
  {
    backImg: alex,
    name: "Field Testing",
    path: "/field-testing",
  },
  {
    backImg: solution,
    name: "Workforce management",
    path: "/workforce-management",
  },
];
const special_Trainee = [
  {
    icon: <VisibilityOutlinedIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "SECRECY",
    description:
      "Ensuring security of prototypes and following Non-disclosure agreements for their secrecy- is a1qa’s forte. We have a dedicated team of experts handling this successfully for over a decade to provide best possible solutions to customers in this regard.",
  },
  {
    icon: <CrisisAlertOutlinedIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "SPECIALIZED TRAINED ENGINEER & EXECUTION EXCELLENCE",
    description:
      "a1qa is made up of an extremely talented pool of trained and specialized Engineers for every sort of projects We insist on excellence in all we do for clients and ourselves, striving always for recognition among the leaders in our industry.",
  },
  {
    icon: (
      <ModeEditOutlineOutlinedIcon fontSize="large" sx={{ color: "blue" }} />
    ),
    title: "GREAT EXPERIENCE",
    description:
      "We always try to Serve you the best. We have a reputed Quality Assurance Team and a host of greatly experienced Managers/ Team Leads who are always happy to stay in touch with Clients in case of any query or remark. Our Test Engineers are hailed as the best in market based on their experience and proven expertise.",
  },
  {
    icon: <SupportAgentSharpIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "FRIENDLY CUSTOMER SERVICE",
    description:
      "We are always happy to serve our Customers in the most friendly yet professional way. a1qa always tries to ensure best possible outcomes for a project by not only supporting it during its tenure but also by extending its helping hand after launch.",
  },
];

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Heading title="About Us " />

      <Box sx={{ background: "#fafafa", padding: "2rem 0rem" }} mt={5}>
        <Container>
          <Typography
            sx={{ textAlign: "center" }}
            fontWeight="400"
            variant="h2"
          >
            Company
          </Typography>
          <Box m="1rem 0rem">
            <Typography
              sx={{ width: "10px", background: "#a26ff7" }}
            ></Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              A1QA is a dynamic company with a proven track record in delivering
              top-notch consumer appliance, mobile, and IoT services. In
              addition, we offer a unique staffing outsourcing solution tailored
              to the testing and service industry.
            </Typography>
            <Typography
              sx={{ padding: "1rem", fontWeight: "bold" }}
              variant="h5"
            >
              Welcome to A1QA: Your Gateway to Tech-Driven Excellence
            </Typography>
            <Typography
              sx={{ padding: "1rem", fontWeight: "bold" }}
              variant="h6"
            >
              Who We Are:
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              At A1QA, we are not just a run-of-the-mill team; we are a dynamic
              assembly of skilled Engineers and Business Strategists, each
              contributing a unique perspective and cultural richness to our
              collective. Our unwavering focus is on revolutionizing market
              strategies for terminal devices, serving a vast customer portfolio
              that extends to over 3 OEMs/ODMs, chipset manufacturers, and
              corporate giants. Our pledge is to lead the way in innovation,
              promoting diversity, and facilitating opportunities that enhance
              the well-being of society as a whole.
            </Typography>
            <Typography
              sx={{ padding: "1rem", fontWeight: "bold" }}
              variant="h6"
            >
              Our Vision:
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              Our vision is to foster a world where technological innovations,
              powered by AI generative solutions, and global mobilization open
              the doors to equal work opportunities for everyone. We firmly
              believe that diversity and inclusivity are not just catchphrases;
              they are potent agents of advancement. By harnessing cutting-edge
              technology, our aim is to dismantle barriers and bridge divides,
              ensuring that everyone has an equal chance at success.
            </Typography>
            <Typography
              sx={{ padding: "1rem", fontWeight: "bold" }}
              variant="h6"
            >
              Our Mission:
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              Our mission is as bold as it is technology-driven - we aspire to
              emerge as India's largest human resource platform, catering to
              both organized and unorganized human resources. We are steadfastly
              dedicated to reshaping the employment landscape in India, making
              it more accessible, equitable, and efficient through innovative
              technology solutions. With unwavering commitment, we aim to bring
              about a profound transformation in the way businesses and
              individuals connect within the Indian job market.
            </Typography>
            <Typography
              sx={{ padding: "1rem", fontWeight: "bold" }}
              variant="h6"
            >
              How We Achieve It:
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              At A1QA, we've meticulously constructed an automated
              infrastructure that seamlessly blends technology and innovation to
              turn our vision into reality. Here's how we do it:
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              <strong>AI-Powered Recruitment:</strong>
              Our team comprises expert recruiters enhanced by AI technology.
              They go beyond conventional recruitment, serving as AI-augmented
              talent scouts who understand your industry and business needs. We
              ensure a perfect match for your requirements, providing services
              at your fingertips.
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              <strong>Personalized Service with AI Account Managers: </strong>
              When you collaborate with us, our AI-driven account managers are
              your steadfast companions. These intelligent digital assistants
              ensure a seamless, personalized experience throughout your journey
              with us, just like chatting with a knowledgeable friend.
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              <strong>AI-Powered Algorithms:</strong>
              We leverage state-of-the-art AI algorithms to optimize and
              streamline the outsourcing process. Our AI algorithm efficiently
              scours vast talent pools, pinpointing the most qualified
              candidates in a fraction of the time it would take traditionally.
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              <strong>Pan India Network:</strong>
              Through our extensive partner network spanning across India, we
              facilitate connections with contract staff tailored precisely to
              your business needs. Whether you operate in e-commerce, startups,
              factories, warehouses, logistics, corporate environments, MSMEs,
              or beyond, we have you covered, providing services on your
              fingertips.
            </Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              At A1QA, we aren't just service providers; we are your strategic
              partners in shaping the future of your workforce. Join us on our
              journey to redefine the Indian job market, forging a world of
              equal opportunities through cutting-edge AI technology,
              innovation, and inclusivity. Together, we can achieve more than
              you ever thought possible - all with technology and innovation
              right at your fingertips.
            </Typography>
          </Box>
          <Grid container spacing={3} mt={10}>
            {special_Trainee.map((item, i) => (
              <Grid key={i} item xs={12} md={6} lg={6}>
                <Paper
                  sx={{
                    padding: 3,
                    backgroundColor: "black",
                    color: "white",
                    minHeight: "300px",
                  }}
                >
                  <Box textAlign="center">
                    <Box mr={2}>{item.icon}</Box>
                    <Box mt={2}>
                      <Typography variant="h6" fontWeight="bold">
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle1" mt={2}>
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Box mt={10}>
            <Typography variant="h4" textAlign="center" p="2rem 0rem">
              Our Solutions
            </Typography>
            <Grid container spacing={3} mt={5}>
              {cards.map((item, i) => (
                <Grid key={i} item sm={6} md={6}>
                  <Card
                    sx={{
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      alt="green iguana"
                      height="140"
                      image={item.backImg}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        textAlign="center"
                        variant="h6"
                        component="div"
                      >
                        {item.name}
                      </Typography>
                    </CardContent>
                    <Box textAlign="center" pb={3}>
                      <Button
                        variant="outlined"
                        style={{
                          borderRadius: "1.5rem",
                          padding: " 11px 2rem",
                          border: "1px solid #b26ff8",
                          fontSize: "14px",
                          fontWeight: "bold",
                          textTransform: "none",
                          textAlign: "center",
                        }}
                        onClick={() => navigate(item.path)}
                      >
                        Read more
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default AboutUs;
