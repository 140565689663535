import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";

import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";

const User = () => {
  return (
    <div>
      <Heading title="User Trial" />

      <Container>
        <Box mt={10}>
          <Typography variant="h6">
            User trial is the critically important process of testing your
            device/product with real users in real scenarios. We offer you a
            kind of user testing globally, which is the fastest and best
            solution to find bugs, defects and usability issues for devices
            which have complex and advanced features of a device. Our target
            users around the world would like to participate in testing and
            provide feedback on different aspects by testing the device/product
            and commit to provide feedback directly to a1qa or to our valuable
            client. Our solution provides device user testing globally the way
            it was always meant to be: lightweight and flexible, fully contained
            by native device technologies. The end result is a genuine,
            unencumbered report illustrating all possibilities to improve the
            product by all means. Taps, swipes, pinches, and any screen
            interaction is shown with precise detail through video results.
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h6">
            Our professional engineers are spread all over the world. In the
            beginning of the project our professionals will help you to collect
            devices from your locations and handover to the coordinator of the
            preferred locations. As well as help coordinator and users to
            delivering a proper introduction of the device (Like what to do and
            what not to, how to take logs, reporting a bag etc).
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h6">
            Watching users try to accomplish tasks on is the most effective and
            efficient way to cover usability problems. Our training will empower
            users to conduct quick and cheap usability tests throughout the
            project lifecycle, without having to set up an official testing
            setup. After learning the benefits of traditional testing techniques
            users will learn how to facilitate sessions, develop, test plans,
            analyze data, and how to report bugs. Before handing over the
            devices to the users we got them signed in a form named NDA where
            some necessary term and conditions, are mentioned which they should
            be followed strictly during the test cycle. ​
          </Typography>
        </Box>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Set up a flexible dedicated team ready to solve your QA tasks from end to end."
        btn="Contact us"
      />

      <Container>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Global pool of users</Typography>
        </Box>
        <Box textAlign="center" mt={7}>
          <Typography variant="h6">
            As a1qa is a global leading testing company and our extremely
            knowledgeable and experienced professional are representing us
            across the global market so we would clearly know what type of users
            you are looking for to complete your project before deadline with
            good human insight. We are the one who can arrange professional
            users for your project within a short notice.
          </Typography>
        </Box>
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Why us?</Typography>
        </Box>
        <Paper
          elevation={3}
          sx={{
            padding: 3,
            marginTop: 8,
            height: "auto",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <Typography variant="subtitle1" fontWeight="bold">
            &bull; We analyze your requirement in terms of the usability test.
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" mt={1}>
            &bull; Have determine the mythology you want to use.
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" mt={1}>
            &bull; Be able to describe to potential participants the objective
            of the project and how the project will be conducted
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" mt={1}>
            &bull; Able to explain properly to all the users about their part in
            the project.
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" mt={1}>
            &bull; After getting project knowledge from clients, we know how
            many users required to complete the project on time.
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" mt={1}>
            &bull; Know what type of users will perform well in the project
            cycle.
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" mt={1}>
            &bull; We would offer best reward for users based on their
            performance.
          </Typography>
        </Paper>
      </Container>

      <Container>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Our policy</Typography>
        </Box>
        <Grid container spacing={5} mt={2} textAlign="center" mb={10}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Target
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Engagement
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Discover
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Share
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                Faster way to get quality human insight
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default User;
