import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";

import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";

const Iot = () => {
  const padd = useMediaQuery("(max-width:1260px)");
  return (
    <div>
      <Heading title="IOT - TWS Testing" />

      <Container>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              elevation={3}
              sx={{
                padding: 5,
                height: "auto",
              }}
            >
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  a1qa is a global provider of outsourced testing services,
                  specializing in IoT and TWS testing. We offer customized
                  solutions to meet our customers’ unique needs, with a focus on
                  quality and reliability. Our team of experienced experts are
                  dedicated to providing the highest level of service, and use
                  the latest technology to ensure that all our customers’
                  requirements are met.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              elevation={3}
              sx={{
                padding: 5,
                height: "auto",
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  At a1qa, we believe that our clients deserve the best, and
                  strive to provide them with the highest quality and most
                  reliable services. We pride ourselves on our commitment to
                  customer satisfaction, with our team always available to
                  answer questions and provide support.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Testing categorized as</Typography>
        </Box>
        <Container>
          <Grid container spacing={5} mt={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  height: "auto",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  &bull; Components Validation
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Function Validation
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Performance Validation
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Security and Data Validation
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Gateway Validation
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  height: "auto",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  &bull; Communication Validation
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Application Test
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Protocol Test
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Feature Test
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Regression Test
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Set up a flexible dedicated team ready to solve your QA tasks from end to end."
        btn="Contact us"
      />
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h5">
            a1qa team successfully executed and completed many IoT-based
            projects below mentioned are the examples of IoT devices for which
            a1qa Team has provided testing services
          </Typography>
        </Box>
        <Container>
          <Grid container spacing={5} mt={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  height: "auto",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  &bull; IoT-based Smart Washers and Washing Machines
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Smart Watches
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Smart Speakers
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Smart Power Plugs and Power Strip
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Smart Bulbs
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Smart Energy meter
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  height: padd ? "auto" : 255,
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  &bull; IoT-based Connected Car
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Connected Cooler
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based People Tracker
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Cattle Tracker
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IoT-based Smart Street Light
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
};

export default Iot;
