import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FactoryIcon from "@mui/icons-material/Factory";
import CardMembershipIcon from "@mui/icons-material/CardMembership";

const Mobile = () => {
  return (
    <div>
      <Heading title="Mobile-Device Testing" />

      <Container>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              elevation={3}
              sx={{
                padding: 5,
                height: "auto",
              }}
            >
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  At a1qa, we strive to provide the best outsourcing services
                  for businesses of any size. Our team is committed to offering
                  efficient, reliable and cost-effective solutions that can help
                  you get ahead of the competition.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              elevation={3}
              sx={{
                padding: 5,
                height: "auto",
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  We specialize in mobile device testing, which can be used to
                  validate the performance, reliability and usability of mobile
                  applications. Our team is equipped with the most advanced
                  tools and technologies to ensure success in this area. With
                  our services, you can rest assured that your mobile
                  applications will perform optimally and provide an excellent
                  user experience.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container spacing={5} mt={5} textAlign="center">
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
                backgroundColor: "black",
                color: "white",
              }}
            >
              <AcUnitIcon sx={{ color: "blue", fontSize: 40 }} />
              <Typography variant="h6" fontWeight="bold">
                Unit Testing
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
                backgroundColor: "black",
                color: "white",
              }}
            >
              <FactoryIcon sx={{ color: "blue", fontSize: 40 }} />
              <Typography variant="h6" fontWeight="bold">
                Factory Testing
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={3}
              sx={{
                padding: 2,
                height: "auto",
                backgroundColor: "black",
                color: "white",
              }}
            >
              <CardMembershipIcon sx={{ color: "blue", fontSize: 40 }} />
              <Typography variant="h6" fontWeight="bold">
                Certification Testing
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Set up a flexible dedicated team ready to solve your QA tasks from end to end."
        btn="Contact us"
      />
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Our portfolio comprises of</Typography>
        </Box>
        <Container>
          <Grid container spacing={5} mt={3}>
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  height: "auto",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  &bull; Performance Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Functional Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Stability Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Network Testing (5G; 4G; 3G; IMS; VoWifi;
                  Interoperability)
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Operator Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; IOT & Smart device Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Certification (GCF; GMS)
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  height: "auto",
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  &bull; Compliance
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Pre-Compliance Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Consumer Trials
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Compatibility Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Usability Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Security Testing
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                  &bull; Automation Testing
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
};

export default Mobile;
