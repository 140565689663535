import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const Heading = ({ title, subtitle }) => {
  const smallSizeActive = useMediaQuery("(max-width:940px)");
  return (
    <div>
      <Box
        display="flex"
        minHeight="400px"
        sx={{ backgroundColor: "black", color: "white" }}
        alignItems="center"
        mt={smallSizeActive ? 6 : 10}
      >
        <Container>
          <Typography variant="h2">{title}</Typography>
          <Typography variant="h6" mt={3}>
            {subtitle}
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default Heading;
