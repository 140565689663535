import React, { useLayoutEffect } from "react";
import Footer from "../../components/Footer";
import GetInTouch from "../../components/GetInTouch";
import Heading from "../../components/Heading";
import Navbar from "../../components/Navbar";
import { useLocation } from "react-router-dom";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function ContactUs() {
  return (
    <Wrapper>
      <Navbar />
      <Heading title="Get in touch " />
      <GetInTouch />

      <Footer />
    </Wrapper>
  );
}
