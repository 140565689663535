import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SpeedIcon from "@mui/icons-material/Speed";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import AnalyticsIcon from "@mui/icons-material/Analytics";

import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";

const Managed = () => {
  const padd = useMediaQuery("(max-width:960px)");
  return (
    <div>
      <Heading
        title="Managed QA"
        subtitle="A service to cover all your software QA needs, so that you can focus on your core business goals"
      />
      <Container>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">
            Managed QA for your long-term transformation programs
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              Helping assure high-class software quality to meet your business
              needs faster at a reasonable cost, managed QA provides
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={5} mt={5}>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <AttachMoneyIcon sx={{ color: "blue", fontSize: 40 }} />
            </Box>
            <Typography variant="subtitle1">
              Decreased total cost of ownership for your QA setup
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <MoneyOffIcon sx={{ color: "blue", fontSize: 40 }} />
            </Box>
            <Typography variant="subtitle1">
              No investment in infrastructure needed
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <EmojiEventsIcon sx={{ color: "blue", fontSize: 40 }} />
            </Box>
            <Typography variant="subtitle1">
              Uncompromised quality at fast-paced delivery
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <SpeedIcon sx={{ color: "blue", fontSize: 40 }} />
            </Box>
            <Typography variant="subtitle1">
              Access to our top-tier tech stack and know-how
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Managed QA services from a1qa embrace
          </Typography>
        </Box>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: padd ? "auto" : 200,
              }}
            >
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  All quality-related issues covered
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  We will adapt our managed QA service pack so that it aligns
                  with your development process and quality-related challenges.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: 200,
              }}
            >
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Transparent efficiency measurement
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  Our experts adjust KPIs to your business needs in order to
                  increase collaboration transparency.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: 200,
              }}
            >
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Digital transformation support
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  Our team tailors QA to achieve high-grade quality goals for
                  all project types, including digital enterprise
                  transformation.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: 200,
              }}
            >
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  High reliability and visibility
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  We ensure the quality of service with SLAs. You will be able
                  to regularly track and view the work in progress via ad-hoc
                  and scheduled reports.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: padd ? "auto" : 200,
              }}
            >
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Toolset and test environment setup
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  We select precise testing tools and configure the test
                  environment for your software to provide proper conditions for
                  running the tests.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: padd ? "auto" : 200,
              }}
            >
              <Box mt={1}>
                <Typography variant="h6" fontWeight="bold">
                  Access to high-skilled talent pool
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  You can choose your team members and interview them, getting
                  control over the composition of your dedicated testing crew
                  that will adapt flexibly to any arisen changes.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Do you want to hand over testing to keep your focus on business matters? Find out more about our managed QA services."
        btn="Contact us"
      />
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Set up your own Testing Center of Excellence
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              As we’ve seen in our practice, managed QA can often transform how
              testing is done at enterprises and trigger establishing a
              proprietary Testing Center of Excellence (TCoE). At a1qa, we help
              you
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={5} mt={5}>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={3}>
              <ContentPasteIcon sx={{ color: "blue", fontSize: 40 }} />
            </Box>
            <Typography variant="subtitle1">
              Evaluate your readiness for a TCoE
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2} mt={1}>
              <HeadsetMicIcon sx={{ color: "blue", fontSize: 40 }} />
            </Box>
            <Typography variant="subtitle1">
              Support you in setting up and managing it
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2} mt={1}>
              <AnalyticsIcon sx={{ color: "blue", fontSize: 40 }} />
            </Box>
            <Typography variant="subtitle1">
              Adopt best practices to assure resource optimization
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Our fundamentals of managed QA</Typography>
        </Box>
        <Container>
          <Grid container spacing={5} mt={5}>
            <Grid item xs={12} md={6} lg={6}>
              <Paper sx={{ padding: 5, minHeight: 200 }} elevation={3}>
                <Typography variant="h5" fontWeight="bold">
                  Commitment to your success
                </Typography>
                <Typography mt={1}>
                  Integrity and reliability are at the core of any relationship.
                  We fully commit to supporting the quality of your software,
                  while also suggesting improvements where applicable.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper sx={{ padding: 5, minHeight: 200 }} elevation={3}>
                <Typography variant="h5" fontWeight="bold">
                  Smart team scalability
                </Typography>

                <Typography mt={1}>
                  We quickly ramp up QA teams and provide an opportunity to
                  scale them up or down when required.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper sx={{ padding: 5, minHeight: 200 }} elevation={3}>
                <Typography variant="h5" fontWeight="bold">
                  QA best practices
                </Typography>
                <Typography mt={1}>
                  Our expertise is based on real-life domain and technology
                  experience, demonstrated by our outstanding track record.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper sx={{ padding: 5, minHeight: 200 }} elevation={3}>
                <Typography variant="h5" fontWeight="bold">
                  Adherence to high standards
                </Typography>
                <Typography mt={1}>
                  With 20 years of delivering added value, we have built a
                  culture of excellence for solving business-critical tasks, and
                  established high standards to support our professionalism.
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper sx={{ padding: 5, minHeight: 200 }} elevation={3}>
                <Typography variant="h5" fontWeight="bold">
                  Unmatched flexibility
                </Typography>
                <Typography mt={1}>
                  We strive to find the best-fitting solution to the client’s
                  unique business challenges through the ‘mix-and-match’
                  approach to QA services.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </div>
  );
};

export default Managed;
