import { Grid, Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Heading from "../../components/Heading";
import CheckIcon from "@mui/icons-material/Check";
import LanguageIcon from "@mui/icons-material/Language";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import ContactOurTeam from "../../components/ContactOurTeam";

const cards = [
  "Non-disclosure agreements in force",
  "Well-established hiring processes",
  "Security incident management in place",
  "Regular security training for employees",
];
const inIT = [
  {
    icon: <LanguageIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "Global security audit",
  },
  {
    icon: <CloudQueueIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "Regular penetration tests",
  },
  {
    icon: <CrisisAlertIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "Ongoing vulnerability scans",
  },
];
const infrastructure = [
  {
    title: "1,100+ heavy-duty workstations",
    subTitle:
      "At our global delivery centers, QA specialists use multi-monitor PC workstations to perform parallel tests under different configurations.",
  },
  {
    title: "Fleet of 300+ mobile devices and wearables",
    subTitle:
      "Based on the global market trends and usage stats, we continuously widen the fleet with the latest and emerging models.",
  },
  {
    title: "Personalized hardware configurations and virtualization tools",
    subTitle:
      "To ascertain maximum performance, our workstations are configured with the latest hardware available, while virtualization tools help roll out a complete hardware configuration, software, and OS within the shortest time.",
  },
  {
    title: "Up-to-date meeting facilities",
    subTitle:
      "Conference rooms across all of our global offices and testing labs are equipped with top-rate technology to help groups of all sizes to conduct on-site and remote meetings with ease.",
  },
];
export default function Testingenv() {
  return (
    <div>
      <Heading
        title="
Testing environment"
        subtitle="Applying the best-of-breed testing methods and tools to ensure all-round software quality"
      />

      <Container>
        <Box sx={{ margin: "4rem 0rem " }}>
          <Typography variant="subtitle1" textAlign="center" mt={1} mb={2}>
            At a1qa, we wisely invest in expanding our tech- and
            industry-focused expertise and create best-fit <br /> combinations
            of infrastructure, tools and resources based on the project goals.
          </Typography>
          <Paper sx={{ padding: 3 }} elevation={3}>
            <Typography variant="h5" fontWeight="bold">
              Environment protection
            </Typography>

            <Typography mt={2}>
              For each client, we create a data sharing infrastructure,
              comprising homogeneous systems located across global data centers.
            </Typography>
            <Box margin="1rem 1rem ">
              <Box display="flex" mb="1rem">
                <CheckIcon
                  sx={{ color: "#a26ff7", marginRight: "0.5rem" }}
                  fontSize="small"
                />
                <Typography variant="body2" fontWeight="bold" mt="0.2rem">
                  Security of operational processes
                </Typography>
              </Box>
              <Box display="flex">
                <CheckIcon
                  sx={{ color: "#a26ff7", marginRight: "0.5rem" }}
                  fontSize="small"
                />
                <Typography variant="body2" fontWeight="bold" mt="0.2rem">
                  {"   "}
                  Security of operational processes
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
        <Box sx={{ margin: "5rem 0rem " }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "3rem", sm: "2rem", xs: "2rem" },
              lineHeight: "56px",
              marginBottom: "4rem",
              textAlign: "center",
            }}
          >
            Creating the atmosphere of trust
          </Typography>

          <Grid container spacing={2}>
            {cards.map((item, i) => (
              <Grid key={i} item xs={6} sm={3} md={3} lg={3}>
                <Paper sx={{ padding: 2, minHeight: 80 }} elevation={3}>
                  <Typography>{item}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ margin: "5rem 0rem " }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "3rem", sm: "2rem", xs: "2rem" },
              lineHeight: "56px",
              marginBottom: "4rem",
              textAlign: "center",
            }}
          >
            In-house IT security monitoring
          </Typography>

          <Grid container spacing={3}>
            {inIT.map((item, i) => (
              <Grid key={i} item xs={12} sm={12} md={4} ld={4}>
                <Paper sx={{ padding: 3, textAlign: "center" }} elevation={3}>
                  {item.icon}
                  <Typography variant="h6" fontWeight="bold">
                    {item.title}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Contact us to learn how our expertise can serve you to enhance your software quality."
        btn="Get in touch"
      />
      <Container>
        <Box sx={{ margin: "5rem 0rem " }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: { md: "3rem", sm: "2rem", xs: "2rem" },
              lineHeight: "56px",
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            a1qa’s infrastructure
          </Typography>
          <Typography variant="subtitle1" textAlign="center">
            With the option to keep uninterrupted work 24/7 due to the dispersed
            nature of our projects, we provide narrow-focused maintenance and
            support throughout the development process, helping solve the most
            complex issues as they arise.
          </Typography>
          <Box>
            <Grid container spacing={5} mt={5}>
              {infrastructure.map((item, i) => (
                <Grid key={i} item xs={12} md={3} lg={3}>
                  <Paper
                    sx={{
                      padding: 3,
                      backgroundColor: "black",
                      color: "white",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      // height: padd ? "auto" : 250,
                    }}
                  >
                    <Box mt={2}>
                      <Typography variant="h6" fontWeight="bold">
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle1" mt={1}>
                        {item.subTitle}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
