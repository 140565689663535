import "./styles/App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import {
  Route,
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
} from "react-router-dom";

//Components imports
import Home from "./pages/home";
import Layout from "./components/Layout/Layout";
import NotFound from "./pages/notFound/404";
import ContactUs from "./pages/contactUs";
import ScrollButton from "./components/ScrollButton";

//Services imports
import LifeCycle from "./pages/services/fullCycle/lifeCycle";
import Managed from "./pages/services/fullCycle/managed";
import Adhoc from "./pages/services/fullCycle/adhoc";
import Automation from "./pages/services/fullCycle/automation";

import Mobile from "./pages/services/completeCoverage/mobile";
import Iot from "./pages/services/completeCoverage/iot";
import Field from "./pages/services/completeCoverage/field";
import User from "./pages/services/completeCoverage/user";
import Laptop from "./pages/services/completeCoverage/laptop";

import EWaste from "./pages/services/eWaste/ewaste";



//Approches imports
import Howwework from "./pages/approach/howwework";
import Testingenv from "./pages/approach/testingenv";
import Processmaturity from "./pages/approach/processmaturity";
import Qaoutsourcing from "./pages/approach/qaoutsourcing";

import AboutUs from "./pages/aboutUs";
import Delivery from "./pages/services/employerSolutions/delivery";
import DeliveryOutsourcing from "./pages/approach/deliveryOutsourcing";
import Outsoucingsolution from "./pages/services/completeCoverage/outsoucingsolution";
import PrivacyPolicy from "./pages/privacyPolicy";
import WorkforceManagement from "./pages/services/employerSolutions/workforceManagement";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />

          {/* Services Routes */}
          <Route path="software-lifecycle-qa" element={<LifeCycle />} />
          <Route path="managed-testing-services" element={<Managed />} />
          <Route path="ad-hoc-testing" element={<Adhoc />} />
          <Route path="test-automation" element={<Automation />} />
          <Route path="outsourcing-solution" element={<Outsoucingsolution />} />
          <Route path="mobile-testing" element={<Mobile />} />
          <Route path="iot-testing" element={<Iot />} />
          <Route path="field-testing" element={<Field />} />
          <Route path="user-trial" element={<User />} />
          <Route path="laptop-testing" element={<Laptop />} />
          <Route path="workforce-management" element={<WorkforceManagement />} />
          <Route path="e-waste" element={<EWaste />} />
          <Route path="devivery-executive" element={<Delivery />} />
         

          {/* Approches Routes */}
          <Route path="how-we-work" element={<Howwework />} />
          <Route path="testing-environment" element={<Testingenv />} />
          <Route path="process-maturity" element={<Processmaturity />} />
          <Route path="qa-outsourcing" element={<Qaoutsourcing />} />
          <Route
            path="delivery-outsourcing"
            element={<DeliveryOutsourcing />}
          />

          <Route path="about-us" element={<AboutUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="/contact-us" element={<ContactUs />} />
      </>
    )
  );
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <ScrollButton />
    </ThemeProvider>
  );
}

export default App;
