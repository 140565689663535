import React, { useLayoutEffect } from "react";
import Navbar from "../Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../Footer";
import GetInTouch from "../GetInTouch";

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default function Layout() {
  return (
    <div
      style={{
        minHeight: "100vh",
        background: "#ffffff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />

      <main
        style={{
          background: "white",
        }}
      >
        <Wrapper>
          <Outlet />
        </Wrapper>
      </main>
      <GetInTouch />
      <div style={{ marginTop: "auto" }}>
        <Footer />
      </div>
    </div>
  );
}
