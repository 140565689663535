import { Box, Grid, Typography, Paper } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Heading from "../../components/Heading";
import NavigateNextSharpIcon from "@mui/icons-material/NavigateNextSharp";
import LooksSharpIcon from "@mui/icons-material/LooksSharp";
import AcUnitSharpIcon from "@mui/icons-material/AcUnitSharp";
import { Link, useNavigate } from "react-router-dom";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CrisisAlertOutlinedIcon from "@mui/icons-material/CrisisAlertOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SupportAgentSharpIcon from "@mui/icons-material/SupportAgentSharp";
const full_LifeCycle = [
  {
    name: " Software lifecycle QA",
    path: "/software-lifecycle-qa",
  },
  {
    name: " Managed testing services ",
    path: "/managed-testing-services",
  },
];
const textCoverage = [
  {
    name: "Mobile Testing ",
    path: "/mobile-testing",
  },
  {
    name: "IOT-TWS Testing",
    path: "/iot-testing",
  },
  {
    name: "Field Testing",
    path: "/field-testing",
  },
  {
    name: "User Trial",
    path: "/user-trial",
  },
  {
    name: "Laptop-Tablet Testing",
    path: "/laptop-testing",
  },
  {
    name: "Outsourcing  & Staffing",
    path: "/outsourcing",
  },
  {
    name: "E-Waste Management",
    path: "/e-waste",
  },
];

const special_Trainee = [
  {
    icon: <VisibilityOutlinedIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "SECRECY",
    description:
      "Ensuring security of prototypes and following Non-disclosure agreements for their secrecy- is a1qa’s forte. We have a dedicated team of experts handling this successfully for over a decade to provide best possible solutions to customers in this regard.",
  },
  {
    icon: <CrisisAlertOutlinedIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "SPECIALIZED TRAINED ENGINEER & EXECUTION EXCELLENCE",
    description:
      "a1qa is made up of an extremely talented pool of trained and specialized Engineers for every sort of projects We insist on excellence in all we do for clients and ourselves, striving always for recognition among the leaders in our industry.",
  },
  {
    icon: (
      <ModeEditOutlineOutlinedIcon fontSize="large" sx={{ color: "blue" }} />
    ),
    title: "GREAT EXPERIENCE",
    description:
      "We always try to Serve you the best. We have a reputed Quality Assurance Team and a host of greatly experienced Managers/ Team Leads who are always happy to stay in touch with Clients in case of any query or remark. Our Test Engineers are hailed as the best in market based on their experience and proven expertise.",
  },
  {
    icon: <SupportAgentSharpIcon fontSize="large" sx={{ color: "blue" }} />,
    title: "FRIENDLY CUSTOMER SERVICE",
    description:
      "We are always happy to serve our Customers in the most friendly yet professional way. a1qa always tries to ensure best possible outcomes for a project by not only supporting it during its tenure but also by extending its helping hand after launch.",
  },
];

const Home = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Heading title="Unlocking success : Your partner for Testing and Staffing Solutions" />
      <Container>
        <Box margin="5rem 0rem">
          <Box textAlign="center" mb="20px">
            <Typography
              variant="h2"
              sx={{
                lineHeight: "3rem",
                marginBottom: "1rem",
                fontWeight: "400",
                fontSize: { xs: "2rem", sm: "3rem" },
              }}
            >
              Our services
            </Typography>
            <Typography
              variant="subtitle1"
              fontSize="20px"
              lineHeight="26px"
              mt={5}
            >
              We offer quality assurance and software testing services for all
              software types, including any PPAs or custom developed software
              deployed to any environment, on different flavors of Agile from
              simple ones to SAFe.
            </Typography>
          </Box>
          <Box sx={{ margin: "5rem 0rem" }}>
            <Grid container spacing={3}>
              <Grid item md={6} sm={12}>
                <Box
                  sx={{
                    boxShadow: " rgba(11, 11, 11, 0.1) 0px 4px 10px 0px",
                    border: "1px solid transparent",
                    paddingBottom: { md: "9.8rem", sm: "1rem" },

                    "&:hover": {
                      boxShadow: "none",
                      border: "1px solid #b26ff8",
                    },
                  }}
                >
                  <Grid
                    sx={{ padding: "1rem 1rem 0rem 1rem" }}
                    container
                    spacing={3}
                  >
                    <Grid item sm={12} md={5}>
                      <Box>
                        <AcUnitSharpIcon
                          fontSize="large"
                          sx={{ color: "blue" }}
                        />
                        <Typography
                          mt={2}
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            marginBottom: "0.7rem",
                          }}
                        >
                          Full-cycle testing services
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={12} md={7}>
                      {full_LifeCycle.map((item, i) => (
                        <Link
                          key={i}
                          to={item.path}
                          onClick={() => navigate(item.path)}
                          style={{ textDecoration: "none", display: "flex" }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              margin: "0.2rem 0rem",
                              color: "rgba(0,0,0,0.8)",

                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {item.name}
                          </Typography>
                          <NavigateNextSharpIcon
                            fontSize="small"
                            sx={{ color: "black", marginTop: "0.5rem" }}
                          />
                        </Link>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={6} sm={12}>
                <Box
                  sx={{
                    boxShadow: " rgba(11, 11, 11, 0.1) 0px 4px 10px 0px",
                    border: "1px solid transparent",
                    paddingBottom: "1rem",
                    "&:hover": {
                      boxShadow: "none",
                      border: "1px solid #b26ff8",
                    },
                  }}
                >
                  <Grid
                    sx={{ padding: "1rem 1rem 0rem 1rem" }}
                    container
                    spacing={3}
                  >
                    <Grid item sm={12} md={5}>
                      <Box>
                        <LooksSharpIcon
                          fontSize="large"
                          sx={{ color: "blue" }}
                        />
                        <Typography
                          mt={2}
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            marginBottom: "0.7rem",
                          }}
                        >
                          Complete test coverage
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={12} md={7}>
                      {textCoverage.map((item, i) => (
                        <Link
                          key={i}
                          to={item.path}
                          onClick={() => navigate(item.path)}
                          style={{ textDecoration: "none", display: "flex" }}
                        >
                          <Typography
                            variant="h6"
                            sx={{
                              margin: "0.2rem 0rem",
                              color: "rgba(0,0,0,0.8)",

                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {item.name}
                          </Typography>
                          <NavigateNextSharpIcon
                            fontSize="small"
                            sx={{ color: "black", marginTop: "0.5rem" }}
                          />
                        </Link>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <Box sx={{ background: "#fafafa", padding: "2rem 0rem" }}>
        <Container>
          <Typography
            sx={{ textAlign: "center", fontWeight: "400" }}
            variant="h2"
          >
            Company
          </Typography>
          <Box m="1rem 0rem" display="flex">
            <Typography
              sx={{ width: "10px", background: "#a26ff7" }}
            ></Typography>
            <Typography sx={{ padding: "1rem" }} variant="h6">
              At a1qa, we are committed to providing the best outsourcing
              services to our clients. We understand the importance of quality
              and timely service, so we make sure that our team of experts
              always provide the best possible service. Our team specializes in
              a wide range of services such as web development, software
              engineering, digital marketing, and more. We strive to provide the
              most cost-effective solutions and are constantly working to expand
              our services. Our team is passionate about helping our clients
              reach their business goals and bring their ideas to life. With our
              dedication to customer satisfaction and quality service, a1qa is
              the perfect partner for any business.
            </Typography>
          </Box>
          <Grid container spacing={3} mt={10}>
            {special_Trainee.map((item, i) => (
              <Grid item xs={12} md={6} lg={6} key={i}>
                <Paper
                  sx={{
                    padding: 3,
                    backgroundColor: "black",
                    color: "white",
                    minHeight: "300px",
                  }}
                >
                  <Box textAlign="center">
                    <Box mr={2}>{item.icon}</Box>
                    <Box mt={2}>
                      <Typography variant="h6" fontWeight="bold">
                        {item.title}
                      </Typography>
                      <Typography variant="subtitle1" mt={2}>
                        {item.description}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
