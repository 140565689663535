import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import React from "react";

import Heading from "../../../components/Heading";

const Laptop = () => {
  return (
    <div>
      <Heading title="Laptop - Tablet Testing" />

      <Container>
        <Box mt={10}>
          <Typography variant="h6">
            Does your Laptop - Tablet function well in different user scenarios?
            We make sure they do. a1qa design a series of scenarios to test your
            Laptop - Tablet devices. We implement user behavior simulations to
            find solutions for potential risks that user might encounter. Our
            team is always updated with new technologies as we are delivering
            testing, training about new technologies and test scope on demand
            basis. Our testing facilities staffed by knowledgeable and
            experienced personnel help you to reduce risks, shorten time to
            market and demonstrate the quality and safety of components and
            products. We also have automated testing tool which improve the
            testing quality and reduce the error that’s helping to compare with
            actual result over the expected result.
          </Typography>
        </Box>
      </Container>

      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Laptop - Tablets testing focus points
          </Typography>
        </Box>
        <Grid container spacing={5} mt={3}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                height: "auto",
                backgroundColor: "black",
                color: "white",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                &bull; Modem testing
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Inbuilt and third-party Application
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Software Performance
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Functional Testing
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Audio and Sound Quality test
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Gaming
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Display and Screen touch
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              elevation={3}
              sx={{
                padding: 3,
                height: "auto",
                backgroundColor: "black",
                color: "white",
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                &bull; HD videos
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Camera
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Battery Testing
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Temperature testing
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Performance testing
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Cold and Warm testing
              </Typography>
              <Typography variant="subtitle1" fontWeight="bold" mt={1}>
                &bull; Hanging/lagging, and much more
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Laptop;
