import { Box, Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ContactOurTeam({ title, subTitle, btn }) {
  const navigate = useNavigate();
  return (
    <div>
      <Container>
        <Box
          sx={{
            background: "black",
            color: "white",
            minHeight: "130px",
            padding: { sm: "1rem", xs: "1rem", md: "1rem 3rem" },
            borderRadius: "10px",
            margin: "5rem 0rem 0rem 0rem",
            boxShadow: "0 20px 45px -15px rgba(2,2,2,.15);",
            display: { sm: "block", xs: "block", md: "flex" },
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Box mr="auto" sx={{ textAlign: { sm: "center", md: "start" } }}>
            <Typography sx={{ fontSize: "2rem", fontWeight: "300" }}>
              {title}
            </Typography>
            <Typography>{subTitle}</Typography>
          </Box>
          <Box
            textAlign="center"
            sx={{ marginTop: { xs: "1rem", sm: "1rem", md: "0rem" } }}
          >
            <Button
              variant="outlined"
              onClick={() => navigate("/contact-us")}
              style={{
                borderRadius: "1.5rem",
                padding: " 10px 1.8rem",
                border: "1px solid blue",
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "none",
                color: "white",
              }}
            >
              {btn}
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
