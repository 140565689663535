import { Grid, Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Heading from "../../components/Heading";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SpeedRoundedIcon from "@mui/icons-material/SpeedRounded";
import CardMembershipRoundedIcon from "@mui/icons-material/CardMembershipRounded";
import ParkRoundedIcon from "@mui/icons-material/ParkRounded";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import CompareArrowsSharpIcon from "@mui/icons-material/CompareArrowsSharp";
import Inventory2SharpIcon from "@mui/icons-material/Inventory2Sharp";
import AllInclusiveSharpIcon from "@mui/icons-material/AllInclusiveSharp";
import DiamondSharpIcon from "@mui/icons-material/DiamondSharp";
import ContactOurTeam from "../../components/ContactOurTeam";
import HeadsetMicRoundedIcon from "@mui/icons-material/HeadsetMicRounded";
import AnimationRoundedIcon from "@mui/icons-material/AnimationRounded";

const outsourced = [
  {
    title: "No overheads",
    subtitle:
      "Get a required number of qualified testing professionals without incurring operational costs.",
    icon: <SpeedRoundedIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "Accelerated time to market",
    subtitle:
      "Ramp up your product delivery speed and efficiency with best QA practices and forefront tools all set up.",
    icon: <AccessTimeIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "Fair costs",
    subtitle:
      "Keep your testing budget under control with fixed quotes and transparent pricing.",
    icon: <CardMembershipRoundedIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "A fresh perspective",
    subtitle:
      "Have an outside-the-box quality assessment from QA professionals helping boost your end-user experience.",
    icon: <ParkRoundedIcon sx={{ color: "blue" }} fontSize="large" />,
  },
];
const accomplish = [
  {
    title: "Accomplished professionals",
    subtitle:
      "Employing over 1,100 full-time QA engineers with diverse skills, areas of expertise, and technical backgrounds, we are fully equipped to handle any software types, from intuitive mobile apps to complex solutions.",
    icon: <PeopleRoundedIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "Flexible arrangements",
    subtitle:
      "We have the resources and management skills to scale up your testing team on short notice when circumstances are pressing and swiftly scale it down, wisely adjusting to your requirements.",
    icon: <CompareArrowsSharpIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "Extensive toolkit",
    subtitle:
      "Well-versed in major platforms and tools for both manual and automated QA, performance and security, a1qa’s engineers cherry-pick a technological set for each particular case, ensuring full and efficient test coverage.",
    icon: <Inventory2SharpIcon sx={{ color: "blue" }} fontSize="large" />,
  },
];
const remoteteam = [
  {
    title: "Remote team ",
    subtitle:
      "We perform your software quality assessment from our office facilities, using a range of communication tools for day-to-day efficient cooperation with the project team, and supplying regular reports to keep you in the loop about the progress.",
    icon: <HeadsetMicRoundedIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "On-site team",
    subtitle:
      "We come to your premises when the testing specialists’ physical presence is required by safety regulations, your corporate policies, or due to other considerations.",
    icon: <PeopleRoundedIcon sx={{ color: "blue" }} fontSize="large" />,
  },
  {
    title: "Mixed team",
    subtitle:
      "We strike the balance between remote and on-site collaboration by composing the QA team from different a1qa testing labs to be at your disposal during your regular business hours while delivering cost-effective services.",
    icon: <AnimationRoundedIcon sx={{ color: "blue" }} fontSize="large" />,
  },
];
const management = [
  {
    title: " Manageability",
    subtitle:
      "To ensure our QA workflows are highly controllable, scalable, and value-driven, a1qa employs proven project management frameworks and tools while having full control over budget and timeline.",
  },
  {
    title: "Transparency",
    subtitle:
      "We set out by creating detailed project documentation and proceed to supply daily, weekly, and monthly status reports throughout the testing phases.",
  },
  {
    title: "Continuous upskilling",
    subtitle:
      "a1qa runs our in-house QA Academy, nurtures its Centers of Excellence and R&D labs, and maintains internal qualification system to ensure continuous learning for our testing engineers and managers.",
  },
];
export default function Qaoutsourcing() {
  return (
    <div>
      <Heading
        title="QA Outsourcing"
        subtitle="Comprehensive software testing on your terms"
      />
      <Container>
        <Box sx={{ margin: "5rem 0rem" }}>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "3rem",
              textAlign: "center",
            }}
          >
            Why outsourced QA is your viable option
          </Typography>
          <Grid container spacing={2}>
            {outsourced.map((item, i) => (
              <Grid key={i} item xs={12} sm={6} md={3} ld={3}>
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    height: "100%",
                    display: "flex",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box mb={1}>{item.icon}</Box>
                  <Typography variant="h6" mb={1} fontWeight="bold">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" mb={1}>
                    {item.subtitle}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box sx={{ margin: "5rem 0rem " }}>
          <Grid container spacing={2}>
            {accomplish.map((item, i) => (
              <Grid key={i} item xs={12} sm={12} md={4} ld={4}>
                <Paper
                  elevation={1}
                  sx={{
                    padding: 2,
                    height: "100%",
                    display: "flex",

                    flexDirection: "column",
                  }}
                >
                  <Box mb={1}>{item.icon}</Box>

                  <Typography variant="h6" mb={1} fontWeight="bold">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" mb={1}>
                    {item.subtitle}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box mt={15}>
          <Typography textAlign="center" variant="h4">
            A full range of QA services
          </Typography>
          <Typography textAlign="center" variant="subtitle1" mt={3}>
            As a pure-play software testing company, a1qa supplies an exhaustive
            set of QA services available for outsourcing:
          </Typography>
          <Grid container m="4rem 0rem" spacing={2}>
            <Grid item xs={12} sm={12} md={6} ld={6}>
              <Paper
                elevation={1}
                sx={{
                  padding: 2,
                  height: "100%",
                  display: "flex",

                  flexDirection: "column",
                }}
              >
                <Box mb={1}>
                  <AllInclusiveSharpIcon
                    sx={{ color: "blue" }}
                    fontSize="large"
                  />
                </Box>
                <Typography variant="h6" mb={1} fontWeight="bold">
                  Complete test coverage
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Mobile Testing
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; IOT-TWS Testing
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Field Testing
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; User Trial
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Laptop-Tablet Testing
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Outsourcing & Staffing
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; E-Waste Management
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={6} ld={6}>
              <Paper
                elevation={1}
                sx={{
                  padding: 2,
                  height: "100%",
                  display: "flex",

                  flexDirection: "column",
                }}
              >
                <Box mb={1}>
                  <DiamondSharpIcon sx={{ color: "blue" }} fontSize="large" />
                </Box>
                <Typography variant="h6" mb={1} fontWeight="bold">
                  Our expert areas
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull;QA consulting
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Full-cycle testing
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull;Test automation
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull;Continuous testing
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Testing in Agile
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Managed QA
                </Typography>
                <Typography variant="body1" mb={1}>
                  &bull; Microservices testing
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        btn="Let’s discuss "
        subTitle="Let a1qa’s team handle quality assurance outsourcing while you take care of your business development."
      />
      <Container>
        <Box sx={{ margin: "5rem 0rem " }}>
          <Typography textAlign="center" variant="h4">
            Choose your engagement model
          </Typography>
          <Typography textAlign="center" variant="subtitle1" mt={3}>
            We offer three distinct collaboration approaches varying in the
            extent of our QA engineers’ engagement:
          </Typography>

          <Grid container mt={3} spacing={2}>
            {remoteteam.map((item, i) => (
              <Grid key={i} item xs={12} sm={12} md={4} ld={4}>
                <Paper
                  sx={{
                    padding: 2,
                    height: "100%",
                    display: "flex",
                    background: "black",
                    flexDirection: "column",
                    color: "white",
                  }}
                >
                  <Box mb={1}>{item.icon}</Box>
                  <Typography variant="h6" mb={1} fontWeight="bold">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" mb={1}>
                    {item.subtitle}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box sx={{ margin: "5rem 0rem " }}>
          <Typography textAlign="center" variant="h4">
            Our priorities in software testing outsourcing
          </Typography>
          <Typography textAlign="center" variant="subtitle1" mt={3}>
            With over 20 years in SQA, we have established a solid strategy for
            the outsourced QA delivery. To provide software testing of
            consistently good quality for varying task scopes, requirements, and
            deadlines, we follow these key principles in our outsourcing service
            delivery:
          </Typography>

          <Grid container mt={3} spacing={2}>
            {management.map((item, i) => (
              <Grid key={i} item xs={12} sm={12} md={4} ld={4}>
                <Paper
                  sx={{
                    padding: 2,
                    height: "100%",
                    display: "flex",
                    textAlign: "center",

                    flexDirection: "column",
                  }}
                >
                  <Typography variant="h6" mb={1} fontWeight="bold">
                    {item.title}
                  </Typography>
                  <Typography variant="body1" mb={1}>
                    {item.subtitle}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </div>
  );
}
