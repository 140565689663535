import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import one from "../../../assets/services/adhoc/one.svg";
import two from "../../../assets/services/adhoc/two.svg";
import three from "../../../assets/services/adhoc/three.svg";
import four from "../../../assets/services/adhoc/four.svg";
import five from "../../../assets/services/adhoc/five.svg";
import seven from "../../../assets/services/adhoc/seven.svg";
import eight from "../../../assets/services/adhoc/eight.svg";
import nine from "../../../assets/services/adhoc/nine.svg";
import ten from "../../../assets/services/adhoc/ten.svg";
import eleven from "../../../assets/services/adhoc/eleven.svg";
import twee from "../../../assets/services/adhoc/tweleve.svg";
import thir from "../../../assets/services/adhoc/thirt.svg";
import img from "../../../assets/services/adhoc/img.png";
import imga from "../../../assets/services/adhoc/imga.png";
import pic from "../../../assets/services/adhoc/pic.png";
import pics from "../../../assets/services/adhoc/pics.png";

import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";

const Adhoc = () => {
  const padd = useMediaQuery("(max-width:1260px)");
  const picBreak = useMediaQuery("(max-width:640px)");
  return (
    <div>
      <Heading
        title="Project-based software testing"
        subtitle="A flexible cooperation program to meet your project needs on request"
      />
      <Container>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">
            When project-based testing is what you need
          </Typography>
        </Box>
        <Grid container spacing={5} mt={5}>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2}>
              <img src={one} alt="one" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              One-off testing
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              You need a one-time assessment of your current software quality.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2}>
              <img src={two} alt="one" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Long-term QA
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              Your software requires continuous testing within a specific QA
              niche.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2}>
              <img src={three} alt="one" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Innovation-driven projects
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              You have to implement new methodologies or practices but want to
              start small with a PoC.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={5} mt={2}>
          <Grid item xs={12} md={2} lg={2}></Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2}>
              <img src={four} alt="one" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Integration
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              You want to make sure that existing software can be integrated
              into your IT landscape.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4} textAlign="center">
            <Box mb={2}>
              <img src={five} alt="one" />
            </Box>
            <Typography variant="h6" fontWeight="bold">
              Compliance
            </Typography>
            <Typography variant="subtitle1" mt={1}>
              You need to assure that your product complies with specific
              standards like HIPAA, GDPR, AAA, or other.
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} lg={2}></Grid>
        </Grid>
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Our project-based testing comes at a fixed price
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3} mb={5}>
              We will help you find the best solution in terms of quality and
              expected outcomes.
            </Typography>
          </Container>
        </Box>
        <Paper sx={{ padding: 3 }} elevation={3}>
          <Grid container spacing={2} mt={5}>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h6" fontWeight="bold">
                You
              </Typography>
              <Box mt={2} display="flex">
                <CheckOutlinedIcon sx={{ color: "blue" }} />
                <Typography variant="subtitle1" ml={1}>
                  Send in the requirements
                </Typography>
              </Box>
              <Box mt={1} display="flex">
                <CheckOutlinedIcon sx={{ color: "blue" }} />
                <Typography variant="subtitle1" ml={1}>
                  Set delivery deadlines and conditions
                </Typography>
              </Box>
              <Typography variant="h6" fontWeight="bold" mt={5}>
                a1qa
              </Typography>
              <Box mt={2} display="flex">
                <CheckOutlinedIcon sx={{ color: "blue" }} />
                <Typography variant="subtitle1" ml={1}>
                  Supports you at defined project stages
                </Typography>
              </Box>
              <Box mt={1} display="flex">
                <CheckOutlinedIcon sx={{ color: "blue" }} />
                <Typography variant="subtitle1" ml={1}>
                  Allocates precisely measured workforces and environments
                </Typography>
              </Box>
              <Box mt={1} display="flex">
                <CheckOutlinedIcon sx={{ color: "blue" }} />
                <Typography variant="subtitle1" ml={1}>
                  Makes the most of QA to achieve quality at speed
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              {padd ? (
                <img src={img} alt="" height="300px" width="350px" />
              ) : (
                <img src={imga} alt="" height="400px" />
              )}
            </Grid>
          </Grid>
        </Paper>
      </Container>
      <ContactOurTeam
        title="Contact our team"
        subTitle="Get in touch with a1qa to try out project-based testing."
        btn="Let’s discuss"
      />
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Your minimal participation for maximal returns
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              When starting your testing project with a1qa, you don’t have to
              think about technicalities or project administration. From the
              team ramp-up to infrastructure setup, we’ve got it all covered for
              you.
            </Typography>
          </Container>
          <Typography variant="h4" mt={5}>
            We make sure you
          </Typography>
        </Box>
        <Grid container spacing={5} mt={3} textAlign="center">
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={seven} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Get your dedicated project manager and team members all carefully
              selected
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={seven} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Stay informed about project goals, task progression, and results
              at all times
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={seven} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Understand the scope of the efforts required on your side in
              advance
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={seven} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Have transparent estimates at hand
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Our project-based software testing stages
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              By closely cooperating with you, we will help you work out the
              scope, terms and deliverables for the project.
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: padd ? "auto" : 250,
              }}
            >
              <img src={eight} alt="one" />
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Scope definition
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  We start out by assessing the testing requirements and plans,
                  advising on the best testing solution.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: 250,
              }}
            >
              <img src={nine} alt="one" />
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  Execution
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  We move to test case design and test execution leading to
                  issue detection and assessment, with bugs recorded in real
                  time via an online tracking tool.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: 250,
              }}
            >
              <img src={ten} alt="one" />
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  Reporting
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  You will be able to control the progress regularly, with
                  detailed progress reports sent daily or at other agreed
                  intervals.
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4" mb={5}>
            A quality architect is assigned to each project for
          </Typography>
        </Box>
        {picBreak ? (
          <img src={pics} alt="" width={380} />
        ) : (
          <img src={pic} alt="" width={padd ? 900 : 1300} />
        )}
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Working with a1qa, you benefit from
          </Typography>
        </Box>
        <Grid container spacing={5} mt={5} textAlign="center">
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={ten} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Evaluate your readiness for a TCoE
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={eleven} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Flexibility in handling any ad-hoc requests
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={twee} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Proactive communication and detailed reporting
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Box mb={3}>
              <img src={thir} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              A complete range of software testing competencies
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Adhoc;
