import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import fac from "../../../assets/images/factories.png";
import logistic from "../../../assets/images/logistics.png";
import manu from "../../../assets/images/manufacturing.png";
import warehouse from "../../../assets/images/warehouse.png";
import it from "../../../assets/images/it.png";
import banking from "../../../assets/images/banking.png";
import candidates from "../../../assets/images/candidate.png";
import payroll from "../../../assets/images/payroll.png";
import workforce from "../../../assets/images/workforce.png";
import mobile from "../../../assets/images/img-08-new.png";

import Heading from "../../../components/Heading";

const data = [
  {
    name: "Factories",
    ig: fac,
  },
  {
    name: "Logistics",
    ig: logistic,
  },
  {
    name: "Manufacturing",
    ig: manu,
  },
  {
    name: "Warehouses",
    ig: warehouse,
  },
  {
    name: "IT/ITeS",
    ig: it,
  },
  {
    name: "Banking",
    ig: banking,
  },
];
const managementData = [
  {
    ig: candidates,
    title: "Candidate search and recruitment ",
    subtitle:
      "Our recruiter network will match the candidate as per the job description and complete all associated verifications. ",
  },
  {
    ig: payroll,
    title: "Payroll Processing",
    subtitle:
      "The A!QAapp will help you create salary structures in seconds and will ensure 100% accurate and compliant payroll processing.",
  },
  {
    ig: workforce,
    title: "Workforce Management",
    subtitle:
      "With the A!QAapp, you will be able to manage your workers easily with automated onboarding, attendance management and an employee self service app.",
  },
];
const WorkforceManagement = () => {
  // const padd = useMediaQuery("(max-width:1260px)");
  return (
    <div>
      <Heading title="Workforce Management" />
      <Container>
        <Box textAlign="center" margin="3rem 0rem ">
          <Typography variant="h4">Industries</Typography>
          <Typography variant="h6" margin="1rem 0rem ">
            With our hundreds of recruiters, dedicated account managers, and
            powerful algorithm, we can help you find the most qualified
            candidates in half the time.
          </Typography>
        </Box>
        <Grid container spacing={5}>
          {data.map((item, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              key={i}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img src={item.ig} alt={item.name} />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", mt: "1rem", textAlign: "center" }}
              >
                {item.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box m={"3rem 0rem"}>
          <Typography textAlign={"center"} variant="h4">
            Workforce Management
          </Typography>
          <Box m={"3rem 0rem "}>
            <Grid container spacing={5}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {managementData.map((item, j) => (
                  <Box display={"flex"} m={1}>
                    <img
                      src={item.ig}
                      width={50}
                      height={70}
                      alt={item.title}
                    />
                    <Box ml={2}>
                      <Typography variant="h5">{item.title}</Typography>
                      <Typography variant="body">{item.subtitle}</Typography>
                    </Box>
                  </Box>
                ))}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: { sm: "none", md: "flex", xl: "flex", xs: "none" },
                  }}
                >
                  <img src={mobile} alt="mobile" height={"500px"} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default WorkforceManagement;
