import React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import logo1 from "../../assets/images/logo.jpeg";
import MenuIcon from "@mui/icons-material/Menu";
import { CssBaseline, Grid, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// const useStyles =
const company = [
  {
    links: [
      {
        name: "About Us ",
        path: "/about-us",
      },
    ],
  },
  {
    links: [
      {
        name: "Contact Us",
        path: "/contact-us",
      },
    ],
  },
];
const approch = [
  {
    links: [
      {
        name: "How We Work",
        path: "/how-we-work",
      },
      {
        name: "Testing Environment ",
        path: "/testing-environment",
      },
    ],
  },
  {
    links: [
      {
        name: "Process Maturity",
        path: "/process-maturity",
      },
 
        {
          name: "Workforce management",
          path: "/workforce-management",
        },
    ],
  },
  
];

const serviceLinks = [
  {
    title: "Full-cycle testing services ",
    links: [
      {
        name: " Software Lifecycle QA",
        path: "/software-lifecycle-qa",
      },
      {
        name: " Managed Testing Services ",
        path: "/managed-testing-services",
      },
      // {
      //   name: "Ad-hoc testing ",
      //   path: "/ad-hoc-testing",
      // },
      // {
      //   name: "Test automation ",
      //   path: "/test-automation",
      // },
    ],
  },
  {
    title: "Complete test coverage",
    links: [
      {
        name: "Mobile Testing ",
        path: "/mobile-testing",
      },
      {
        name: "IOT-TWS Testing",
        path: "/iot-testing",
      },
      {
        name: "Field Testing",
        path: "/field-testing",
      },
      {
        name: "User Trial",
        path: "/user-trial",
      },
      {
        name: "Laptop-Tablet Testing",
        path: "/laptop-testing",
      },
      // {
      //   name: "Outsourcing  & Staffing Solution ",
      //   path: "/outsourcing-solution",
      // },
    ],
  },
  
  {
    title: "Employer Solutions",
    links: [
      {
        name: "Workforce management",
        path: "/workforce-management",
      },
      // {
      //   name: "Delivery Executives Outsourcing",
      //   path: "/devivery-executive",
      // },
    ],
  },
  {
    title: "E-Waste Management",
    links: [
      {
        name: "E-Waste Management",
        path: "/e-waste",
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  active: {
    fontSize: "1rem",
    padding: "2.5rem 1.2rem",

    cursor: "pointer",
    color: "#000",
    borderTop: "2px solid transparent", // Add a transparent border to compensate for the hover border
    "&:hover": {
      borderTop: "2px solid #b26ff8",
    },
  },
  textUnderline: {
    color: "black",
    margin: "0.5rem 0rem",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export default function Navbar() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isService, setIsService] = useState(false);
  const [isApproch, setIsApproch] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [smallSizeService, setSmallSizeService] = useState(false);
  const [smallSizeApproch, setSmallSizeApproch] = useState(false);
  const [smallSizeCompany, setSmallSizeCompany] = useState(false);
  const [smallNavActive, setSmallNavActive] = useState(false);

  // const smallSizeActive = useMediaQuery("(max-width:600px)");
  const navMenuIcon = useMediaQuery("(max-width:960px)");

  const handleapprochCLick = () => {
    setIsApproch(false);
  };
  const handleServicesClick = () => {
    setIsService(false);
  };
  const handleCompanyClick = () => {
    setIsCompany(false);
  };
  const handleSmallSizeLinkClick = () => {
    setSmallSizeService(false);
    setSmallSizeApproch(false);
    setSmallSizeCompany(false);
    setSmallNavActive(false);
  };

  const handleServiceEnter = () => {
    setIsService(true);
  };
  const handleMenuIconClick = (active) => {
    setSmallNavActive(true);
    if (!active) {
      setSmallNavActive(false);
    }
  };
  const handleCrossClick = (event) => {
    event.stopPropagation();
    setSmallNavActive(false);
    setSmallSizeService(false);
  };
  return (
    <div>
      <Box sx={{ position: "relative" }}>
        <CssBaseline />
        <AppBar
          elevation={2}
          sx={{
            backgroundColor: "white",
            padding: { sm: "0rem -1rem", md: "0rem 2rem" },
          }}
          component="nav"
        >
          <Toolbar>
            <Box
              sx={{
                flexGrow: 1,
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <img
                src={logo1}
                alt="logo"
                style={{
                  width: navMenuIcon ? "100px" : "150px",
                  height: navMenuIcon ? "50px" : "100px",
                  objectFit: "contain",
                }}
              />
            </Box>

            <Box sx={{ flexGrow: 2, display: { xs: "none", md: "flex" } }}>
              <Box onMouseLeave={() => setIsService(false)}>
                <Typography
                  variant="body1"
                  className={classes.active}
                  onMouseEnter={handleServiceEnter}
                >
                  Services
                </Typography>
                {isService && (
                  <Box
                    sx={{
                      position: "absolute",
                      background: "white",
                      color: "black",
                      width: "111%",
                      

                      left: "-5.5%",
                      right: 0,
                      top: "100%",
                      borderTop: "1px solid #f5f5f5",
                      padding: "3rem 6rem",
                    }}
                  >
                    <Grid container spacing={5}>
                      {serviceLinks.map((item, i) => (
                        <Grid item md={3} xl={3} key={i}>
                          <Typography
                            sx={{
                              fontSize: "1.2rem",
                              fontWeight: "bold",
                              marginBottom: "0.7rem",
                            }}
                          >
                            {item.title}
                          </Typography>
                          {item.links.map((link, j) => (
                            <Link
                              key={j}
                              to={link.path}
                              onClick={handleServicesClick}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography
                                sx={{ margin: "1rem 0rem" }}
                                className={classes.textUnderline}
                              >
                                {link.name}
                              </Typography>
                            </Link>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box onMouseLeave={() => setIsApproch(false)}>
                <Typography
                  variant="body1"
                  className={classes.active}
                  onMouseEnter={() => setIsApproch(true)}
                >
                  Approach
                </Typography>
                {isApproch && (
                  <Box
                    sx={{
                      position: "absolute",
                      background: "white",
                      color: "black",
                      width: "111%",
                      // minHeight: "300px",

                      left: "-5.5%",
                      right: 0,
                      top: "100%",
                      borderTop: "1px solid #f5f5f5",
                      padding: "3rem 6rem",
                    }}
                  >
                    <Grid container spacing={5}>
                      {approch.map((item, i) => (
                        <Grid item md={4} key={i}>
                          {item.links.map((link, j) => (
                            <Link
                              key={j}
                              to={link.path}
                              onClick={handleapprochCLick}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography
                                sx={{ margin: "1rem 0rem" }}
                                className={classes.textUnderline}
                              >
                                {link.name}
                              </Typography>
                            </Link>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Box>

              <Box onMouseLeave={() => setIsCompany(false)}>
                <Typography
                  variant="body1"
                  className={classes.active}
                  onClick={handleCompanyClick}
                  onMouseEnter={() => setIsCompany(true)}
                >
                  Company
                </Typography>
                {isCompany && (
                  <Box
                    sx={{
                      position: "absolute",
                      background: "white",
                      color: "black",
                      width: "111%",
                      // minHeight: "300px",

                      left: "-5.5%",
                      right: 0,
                      top: "100%",
                      borderTop: "1px solid #f5f5f5",
                      padding: "3rem 6rem",
                    }}
                  >
                    <Grid container spacing={5}>
                      {company.map((item, i) => (
                        <Grid item md={4} key={i}>
                         {item.links.map((link, j) => (
                            <Link
                              key={j}
                              to={link.path}
                              onClick={handleCompanyClick}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography
                                sx={{ margin: "1rem 0rem" }}
                                className={classes.textUnderline}
                              >
                                {link.name}
                              </Typography>
                            </Link>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ flex: "end" }}>
              <Button
                variant="outlined"
                onClick={() => navigate("/contact-us")}
                sx={{
                  borderRadius: "1.5rem",
                  padding: navMenuIcon ? "7px 1.5rem" : " 10px 1.8rem",
                  border: "1px solid blue",
                  fontSize: navMenuIcon ? "14px" : "16px",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                Contact Us
              </Button>
            </Box>
            {!smallNavActive && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                //   onClick={() => {
                //     setOpen(true);
                //   }}
                onClick={() => handleMenuIconClick(navMenuIcon)}
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  color: "black",
                  ml: "1rem",
                }}
              >
                <MenuIcon fontSize={navMenuIcon ? "medium" : "medium"} />
              </IconButton>
            )}

            {smallNavActive && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                //   onClick={() => {
                //     setOpen(true);
                //   }}
                onClick={handleCrossClick}
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  color: "black",
                  ml: "1rem",
                }}
              >
                <CloseIcon fontSize={navMenuIcon ? "medium" : "medium"} />
              </IconButton>
            )}
            {smallNavActive && navMenuIcon && (
              <Box
              sx={{
                  position: "absolute",
                  color: "black",
                  background: "white",

                  width: "111%",
                  height: "100vh",

                  left: "-5.5%",
                  right: 0,
                  top: "101%",
                  borderTop: "1px solid #f5f5f5",
                  padding: "1rem 2rem",
                  overflow: "scroll",
                }}
              >
                {smallSizeService && (
                  <Box>
                    <Typography
                      component="span"
                      onClick={() => setSmallSizeService(false)}
                      style={{
                        display: "flex",
                        cursor: "pointer",
                        padding: "1rem 0rem", // Add this line to apply the pointer cursor
                      }}
                    >
                      <ChevronLeftIcon fontSize="small" />
                      Back
                    </Typography>
                    {/* <Button>
                        back
                      </Button> */}
                    <Grid container spacing={2}>
                      {serviceLinks.map((item, i) => (
                        <Grid item sm={6} xs={12} md={4} key={i}>
                          <Typography
                            sx={{
                              fontSize: "1.2rem",
                              fontWeight: "bold",
                              marginBottom: "1rem",
                            }}
                          >
                            {item.title}
                          </Typography>
                          {item.links.map((link, j) => (
                            <Link
                            key={j}
                            to={link.path}
                            onClick={handleSmallSizeLinkClick}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              style={{ margin: "0.5rem 0rem" }}
                              className={classes.textUnderline}
                            >
                              {link.name}
                            </Typography>
                          </Link>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
                {smallSizeApproch && (
                  <Box>
                    <Typography
                      component="span"
                      onClick={() => setSmallSizeApproch(false)}
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        padding: "1rem 0rem", // Add this line to apply the pointer cursor
                      }}
                    >
                      <ChevronLeftIcon fontSize="small" />
                      Back
                    </Typography>
                    {/* <Button>
                        back
                      </Button> */}
                    <Grid container spacing={3}>
                      {approch.map((item, i) => (
                        <Grid item sm={4} xs={12} md={4} key={i}>
                          {item.links.map((link, j) => (
                            <Link
                              key={j}
                              to={link.path}
                              onClick={handleSmallSizeLinkClick}
                              style={{ textDecoration: "none" }}
                            >
                              <Typography
                                style={{ margin: "1rem 0rem" }}
                                className={classes.textUnderline}
                              >
                                {link.name}
                              </Typography>
                            </Link>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
                {smallSizeCompany && (
                  <Box>
                    <Typography
                      component="span"
                      onClick={() => setSmallSizeCompany(false)}
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        padding: "1rem 0rem", // Add this line to apply the pointer cursor
                      }}
                    >
                      <ChevronLeftIcon fontSize="small" />
                      Back
                    </Typography>
                    {/* <Button>
                        back
                      </Button> */}
                    <Grid container spacing={3}>
                      {company.map((item, i) => (
                        <Grid item md={4} sm={4} xs={12} key={i}>
                          {item.links.map((link, j) => (
                            <Link
                            key={j}
                            to={link.path}
                            onClick={handleSmallSizeLinkClick}
                            style={{ textDecoration: "none" }}
                          >
                            <Typography
                              style={{ margin: "1rem 0rem" }}
                              className={classes.textUnderline}
                            >
                              {link.name}
                            </Typography>
                          </Link>
                          ))}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                )}
                {!smallSizeService &&
                  !smallSizeApproch &&
                  !smallSizeCompany && (
                    <Box
                      sx={{
                        display: "flex",
                        padding: "0.5rem 0rem",
                        cursor: "pointer",
                      }}
                      onClick={() => setSmallSizeService(true)}
                    >
                      <Typography sx={{ flex: "1", fontSize: "0.8rem" }}>
                        Service
                      </Typography>
                      <ChevronRightIcon fontSize="small" />
                    </Box>
                  )}
                {!smallSizeService &&
                  !smallSizeApproch &&
                  !smallSizeCompany && (
                    <Box
                      sx={{
                        display: "flex",
                        padding: "0.5rem 0rem",
                        cursor: "pointer",
                      }}
                      onClick={() => setSmallSizeApproch(true)}
                    >
                      <Typography sx={{ flex: "1", fontSize: "0.8rem" }}>
                        Approach
                      </Typography>
                      <ChevronRightIcon fontSize="small" />
                    </Box>
                  )}
                {!smallSizeService &&
                  !smallSizeApproch &&
                  !smallSizeCompany && (
                    <Box
                      onClick={() => setSmallSizeCompany(true)}
                      sx={{
                        display: "flex",
                        padding: "0.5rem 0rem",
                        cursor: "pointer",
                      }}
                    >
                      <Typography sx={{ flex: "1", fontSize: "0.8rem" }}>
                        Company
                      </Typography>
                      <ChevronRightIcon fontSize="small" />
                    </Box>
                  )}
              </Box>
            )}

            {/* Add any other components you want to display on the right side */}
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
}
