import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import React, { useState } from "react";

import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  box: {
    background: "#fafafa",
    width: "100%",
    borderRadius: "1rem",
    boxShadow: "0 20px 45px -15px rgba(2,2,2,.15);",
    margin: "2rem 0rem",
  },
});

export default function GetInTouch() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isAgree, setIsAgree] = useState(false);
  const [agreeError, setagreeError] = useState(false);

  const [projectDescription, setProjectDescription] = useState("");

  // Route Name
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isAgree) {
      setagreeError(true);
      return;
    } else {
      setagreeError(false);
      console.log(name, email, phone, isAgree, company, projectDescription);
    }
  };

  return (
    <div>
      <Container>
        <Box className={classes.box}>
          <Box padding="2rem 0rem">
            {location.pathname !== "/contact-us" && (
              <Typography
                variant="h2"
                sx={{
                  fontSize: { sm: "1.5rem", md: "3rem", xs: "2rem" },
                  textAlign: "center",
                  fontWeight: "400",
                }}
              >
                Get In Touch
              </Typography>
            )}
            {location.pathname === "/contact-us" && (
              <>
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: { sm: "1.5rem", md: "3rem" },
                    textAlign: "center",
                    fontWeight: "400",
                  }}
                >
                  We are glad to hear from you!
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "0.8rem", md: "1rem" },
                    textAlign: "center",
                    fontWeight: "400",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  Thank you for your interest in a1qa. Please fill in the form
                  below, and our tech expert will get back to you shortly.
                </Typography>
              </>
            )}
          </Box>

          <Box
            sx={{
              padding: { sm: "  0.5rem", md: " 2rem" },
            }}
          >
            <form
              // onSubmit={handleSubmit}
              method="POST"
              action={`https://getform.io/f/${process.env.REACT_APP_FORM_API}`}
              // encType="multipart/form-data"
            >
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    required
                    variant="outlined"
                    fullWidth
                    label="Name"
                    size="small"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{
                      marginBottom: "2rem",
                      background: "#ffffff",
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#000000",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                    }}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Company"
                    size="small"
                    name="company"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    sx={{
                      marginBottom: "2rem",
                      background: "#ffffff",
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#000000",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                    }}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Email"
                    required
                    size="small"
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{
                      marginBottom: "2rem",
                      background: "#ffffff",
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#000000",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                    }}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Phone"
                    size="small"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    sx={{
                      marginBottom: "2rem",
                      color: "black",
                      background: "#ffffff",
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                      "& .MuiFormLabel-root": {
                        color: "#000000",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "#b26ff8",
                        },
                    }}
                  />
                </Grid>
              </Grid>
              <TextField
                multiline
                minRows={4}
                fullWidth
                variant="outlined"
                name="description"
                value={projectDescription}
                onChange={(e) => setProjectDescription(e.target.value)}
                label="Description"
                required
                sx={{
                  marginBottom: "2rem",
                  color: "black",
                  background: "#ffffff",
                  "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#b26ff8",
                    },
                  "& .MuiFormLabel-root": {
                    color: "#000000",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#b26ff8",
                    },
                }}
              />
              <Box display="flex">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAgree}
                      onChange={(e) => setIsAgree(e.target.checked)}
                      size="small"
                      sx={{ color: agreeError ? "red" : "" }}
                    />
                  }
                />
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "0.8rem",
                    marginTop: "0.5rem",
                  }}
                >
                  I hereby give my consent for a1qa and its affiliates to
                  process my personal data in accordance with Privacy and
                  Cookies Policy for the purpose of handling my request and
                  responding to it. I am aware of the fact that I have the right
                  to withdraw my consent at any time. <br />
                  {agreeError && (
                    <Typography sx={{ color: "red", fontSize: "0.7rem" }}>
                      Please accept the term to proceed
                    </Typography>
                  )}
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                <Button
                  variant="outlined"
                  type="submit"
                  style={{
                    borderRadius: "1.5rem",
                    padding: " 11px 2rem",
                    border: "1px solid #b26ff8",
                    fontSize: "18px",
                    fontWeight: "bold",
                    textTransform: "none",
                    textAlign: "center",
                    marginBottom: "2rem",
                  }}
                  disabled={!isAgree}
                >
                  Send a message
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
