import { Grid, Paper, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import Heading from "../../components/Heading";
import TimelineIcon from "@mui/icons-material/Timeline";
import SettingsIcon from "@mui/icons-material/Settings";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import SchoolIcon from "@mui/icons-material/School";
import PeopleIcon from "@mui/icons-material/People";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ContactOurTeam from "../../components/ContactOurTeam";

const cards = [
  {
    icon: <PeopleIcon fontSize="large" sx={{ color: "white" }} />,
    name: "Easily create a dedicated QA team",
  },
  {
    icon: <AllInclusiveIcon fontSize="large" sx={{ color: "white" }} />,
    name: "Easily create a dedicated QA team",
  },
  {
    icon: <CompareArrowsIcon fontSize="large" sx={{ color: "white" }} />,
    name: "Easily create a dedicated QA team",
  },
  {
    icon: <DriveFileMoveIcon fontSize="large" sx={{ color: "white" }} />,
    name: "Easily create a dedicated QA team",
  },
  {
    icon: <SchoolIcon fontSize="large" sx={{ color: "white" }} />,
    name: "Easily create a dedicated QA team",
  },
];

export default function Processmaturity() {
  return (
    <div>
      <Heading
        title="Process maturity"
        subtitle="Fine-tuned processes to power the delivery of high-quality software"
      />
      <Container>
        <Box sx={{ margin: "5rem 0rem " }}>
          <Typography textAlign="center" variant="h3">
            Seamless integration into development processes
          </Typography>
          <Grid container spacing={3} mt={5}>
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  padding: 3,
                  backgroundColor: "#19152F",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <Box mb={2}>
                  <TimelineIcon fontSize="large" sx={{ color: "white" }} />
                </Box>

                <Typography variant="subtitle1">
                  Almost two decades of QA experience
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  padding: 3,
                  backgroundColor: "#19152F",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <Box mb={2}>
                  <EmojiPeopleIcon fontSize="large" sx={{ color: "white" }} />
                </Box>

                <Typography variant="subtitle1">
                  Client-centric mindset
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Paper
                sx={{
                  padding: 3,
                  backgroundColor: "#19152F",
                  color: "white",
                  textAlign: "center",
                }}
              >
                <Box mb={2}>
                  <SettingsIcon fontSize="large" sx={{ color: "white" }} />
                </Box>

                <Typography variant="subtitle1">Service flexibility</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            Proprietary engagement policy for complete flexibility{" "}
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              Within the culture of excellence, we tirelessly polish our
              approach to software QA.
            </Typography>
            <Typography variant="subtitle1" mt={3}>
              What remains unchangeable is the in-house engagement policy
              created for seamless integration into any project at any SDLC
              stage and responding to project challenges. To accomplish that
              successfully for every industry and product and meet planned
              outcomes, we
            </Typography>
          </Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            textAlign="left"
            m="3rem 0rem"
          >
            <Box sx={{ marginRight: "1rem" }}>
              <Typography
                sx={{ width: "1px", background: "#a26ff7", height: "100%" }}
              ></Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                1. Combine advantages of all the applicable methodologies
              </Typography>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                2. Modify QA processes to the clients’ demands
              </Typography>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                3. Align with the industry best practices
              </Typography>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                4. Establish efficient and controllable processes
              </Typography>
            </Box>
          </Box>
        </Box>

        <ContactOurTeam
          title="Contact our team"
          btn="Contact us "
          subTitle="Drop us a line to virtually meet our QA experts and discuss how we can help meet your business needs."
        />

        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Ongoing QA process optimization</Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              When our partners wish to fine-tune their internal operations, we
              provide QA consulting, using our independent QA team setup service
              and relying on our long-standing experience of helping a range of
              clients improve their processes.
            </Typography>
            <Typography variant="subtitle1" mt={3}>
              With a tailor-made software testing approach aimed at delivering
              operational and business values and the expertise accumulated
              within our Center of Excellence for QA consulting, you can
            </Typography>
          </Container>
          <Grid container mt={3} spacing={3}>
            {cards.map((item, i) => (
              <Grid key={i} item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    padding: 3,
                    backgroundColor: "black",
                    color: "white",
                  }}
                >
                  <Box mb={2}>{item.icon}</Box>

                  <Typography variant="subtitle1">{item.name}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Box textAlign="center" mt={15}>
          <Typography variant="h3">
            a1qa’s communication and reporting principles
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            textAlign="left"
            m="3rem 0rem"
          >
            <Box sx={{ marginRight: "1rem" }}>
              <Typography
                sx={{ width: "1px", background: "#a26ff7", height: "100%" }}
              ></Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                1. QA process transparency
              </Typography>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                2. Continuous KPIs measurement
              </Typography>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                3. Sharing of only relevant information
              </Typography>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                4. Use of collaboration platforms to keep track of communication
              </Typography>
              <Typography variant="subtitle1" m={1} fontWeight="bold">
                5. Smooth project integration
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
