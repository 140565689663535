import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import one from "../../../assets/services/automation/one.svg";
import two from "../../../assets/services/automation/two.svg";
import three from "../../../assets/services/automation/three.svg";
import four from "../../../assets/services/automation/four.svg";
import five from "../../../assets/services/automation/five.svg";
import seven from "../../../assets/services/automation/seven.svg";
import eight from "../../../assets/services/automation/eight.svg";
import nine from "../../../assets/services/automation/nine.svg";
import ten from "../../../assets/services/automation/ten.svg";
import eleven from "../../../assets/services/automation/eleven.svg";
import twee from "../../../assets/services/automation/twele.svg";
import thir from "../../../assets/services/automation/thir.svg";
import fourt from "../../../assets/services/automation/fourt.svg";
import fift from "../../../assets/services/automation/fifty.svg";
import pic from "../../../assets/services/automation/pic.png";
import pics from "../../../assets/services/automation/pics.png";
import img from "../../../assets/services/automation/img.png";
import imga from "../../../assets/services/automation/imga.png";
import img1 from "../../../assets/services/automation/img1.webp";
import img2 from "../../../assets/services/automation/img2.webp";
import img3 from "../../../assets/services/automation/img3.webp";
import img4 from "../../../assets/services/automation/img4.webp";
import img5 from "../../../assets/services/automation/img5.webp";

import Heading from "../../../components/Heading";
import ContactOurTeam from "../../../components/ContactOurTeam";

const testing = [
  {
    name: "functionality",
    title: "Functionality",
    subtitle:
      "Call on the a1qa engineers throughout the SDLC, and we develop an efficient solution to automate laborious smoke testing and continuously evaluate software in progress against established functional requirements.",
    img: img1,
  },
  {
    name: "performance",
    title: "Performance",
    subtitle:
      "When in need of an exhaustive assessment of your software’s operational stability under an array of ordinary and extreme conditions, turn to a1qa for performance testing automation. To help you easily detect bottlenecks and limitations, we devise a framework for streamlining:",
    img: img2,
    points: ["Load testing", "Stress testing", "Endurance testing"],
  },
  {
    name: "integration",
    title: "Integration",
    subtitle:
      "Involve the a1qa specialists at the early development stages to set up a system that seeks out faults and bottlenecks in interactions between system modules, components, third-party tools, and other elements.",
    img: img3,
  },
  {
    name: "security",
    title: "Security",
    subtitle:
      "If you are looking to speed up the security QA workflow without compromising on the quality and scope of the analysis, a1qa can upgrade your CI/CD pipeline with an automated security testing framework. We facilitate the following cybersecurity domains with automation:",
    img: img4,
    points: [
      "Vulnerability scanning ",
      "Penetration testing",
      "Compliance testing",
      "Static code analysis",
    ],
  },
  {
    name: "operation",
    title: "Multi-platform operation",
    subtitle:
      "When you need to verify the end product’s uniform look and feel and seamless performance across a multitude of operating conditions, a1qa offers to automate this labor-intensive process with proven tools and practices. Our QA automation team provides for:",
    img: img5,
    points: [
      "Cross-browser testing",
      "Cross-platform testing",
      "Cross-device testing",
    ],
  },
];

const Automation = () => {
  const padd = useMediaQuery("(max-width:1260px)");
  const picBreak = useMediaQuery("(max-width:640px)");

  const [types, setTypes] = useState("functionality");

  const result = testing.find((obj) => {
    return obj.name === types;
  });

  return (
    <div>
      <Heading
        title="Test automation"
        subtitle="Combining best practices and emerging tech such as AI and ML to build your automated QA workflows"
      />
      <Container>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">Rely on automated testing to:</Typography>
        </Box>
        <Grid container spacing={5} mt={5}>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <img src={one} alt="one" />
            </Box>

            <Typography variant="subtitle1">
              Save effort and time on high-volume testing activities
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <img src={two} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Enhance the breadth of in-house testing capabilities without
              hiring extra human resources
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <img src={three} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Eliminate human error in high-risk QA processes
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} lg={3} textAlign="center">
            <Box mb={2}>
              <img src={four} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Spot errors and malfunctions in the released software as soon as
              they occur
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">
            What test automation brings to your SDLC
          </Typography>
        </Box>
        <Container>
          <Grid container spacing={5} mt={5}>
            <Grid item xs={12} md={6} lg={6}>
              <Box>
                <img src={five} alt="" width={padd ? "300px" : ""} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography variant="h5" fontWeight="bold">
                Acceleration
              </Typography>
              <Typography mt={1}>
                Automation facilitates shifting quality assurance left and
                uncovering critical bugs early in the SDLC.
              </Typography>
              <Typography variant="h5" fontWeight="bold" mt={2}>
                Testing cycle optimization
              </Typography>
              <Typography mt={1}>
                Automation allows for tapping into Agile practices, such as
                task-based and behavior-driven development.
              </Typography>
              <Typography variant="h5" fontWeight="bold" mt={2}>
                DevOps transformation
              </Typography>
              <Typography mt={1}>
                Automation enables setting up a continuous testing environment,
                which is an inherent part of DevOps.
              </Typography>
              <Typography variant="h5" fontWeight="bold" mt={2}>
                Granular quality assessment
              </Typography>
              <Typography mt={1}>
                Automation makes it possible to embed quality gates into the
                testing lifecycle, thus strengthening quality control.
              </Typography>
              <Typography variant="h5" fontWeight="bold" mt={2}>
                Competitive edge
              </Typography>
              <Typography mt={1}>
                Automation is rapidly replacing manual and model-based QA,
                becoming an essential requirement in any competitive software
                development project.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>

      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">Automation by testing types</Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              a1qa offers to automate quality assurance of the following areas
              in web, desktop, and mobile software:
            </Typography>
          </Container>
        </Box>
        <Container>
          <Box mt={5} display="flex" overflow="auto">
            <Box mr={3}>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  padding: "20px 50px 20px 50px",
                  fontSize: 18,
                }}
                onClick={() => setTypes("functionality")}
              >
                Functionality
              </Button>
            </Box>
            <Box mr={3}>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  padding: "20px 50px 20px 50px",
                  fontSize: 18,
                }}
                onClick={() => setTypes("performance")}
              >
                Performance
              </Button>
            </Box>
            <Box mr={3}>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  padding: "20px 50px 20px 50px",
                  fontSize: 18,
                }}
                onClick={() => setTypes("integration")}
              >
                Integration
              </Button>
            </Box>
            <Box mr={3}>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  padding: "20px 40px 20px 40px",
                  fontSize: 18,
                }}
                onClick={() => setTypes("security")}
              >
                Security
              </Button>
            </Box>
            <Box>
              <Button
                variant="outlined"
                size="large"
                sx={{
                  textTransform: "none",
                  padding: "20px 50px 20px 50px",
                  fontSize: 18,
                }}
                onClick={() => setTypes("operation")}
              >
                Multi-platform operation
              </Button>
            </Box>
          </Box>
        </Container>
        <Grid container spacing={5} p={5}>
          <Grid item xs={12} md={6} lg={6}>
            <Typography variant="h4" fontWeight="bold" mt={3}>
              {result.title}
            </Typography>
            <Typography variant="subtitle1" mt={3}>
              {result.subtitle}
            </Typography>
            {result.points &&
              result?.points.map((item, i) => (
                <Typography variant="subtitle1" mt={2} key={i}>
                  &bull; {item}
                </Typography>
              ))}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box>
              {padd ? (
                <img src={result.img} alt="img" width={300} height={280} />
              ) : (
                <img src={result.img} alt="img" width={550} height={400} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>

      <ContactOurTeam
        title="Contact our team"
        subTitle="Let’s discuss how our test automation experts can assist your company."
        btn="Contact Us"
      />
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15} mb={5}>
          <Typography variant="h4" mb={5}>
            Test automation lifecycle made easy
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              As you hand over your software to the a1qa team, we follow through
              with a proven strategy to deliver thorough, value-driven service.
              These are the key milestones of our refined QA automation roadmap:
            </Typography>
          </Container>
        </Box>
        {picBreak ? (
          <img src={img} alt="" width={380} />
        ) : (
          <img src={imga} alt="" width={padd ? 900 : 1300} />
        )}
      </Container>
      <Container>
        <Box textAlign="center" mt={10}>
          <Typography variant="h4">Test automation consulting</Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              If you consider streamlining your legacy QA processes, rely on
              a1qa’s consultants to shape your vision into an actionable
              strategy.
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={3} mt={5}>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
                height: padd ? "auto" : 237,
              }}
            >
              <img src={seven} alt="one" />
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  What it is
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  Our team interviews your key stakeholders to outline the
                  target automation scope and desired transformation outcomes.
                  We also explore your current testing workflow to recognize
                  opportunities for automation.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
              }}
            >
              <img src={eight} alt="one" />
              <Box mt={2}>
                <Typography variant="h6" fontWeight="bold">
                  What you get
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  We work out a set of processes and tools, which are most
                  suitable for testing automation in your particular case, and
                  deliver a roadmap of your QA automation journey.
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Paper
              sx={{
                padding: 3,
                backgroundColor: "black",
                color: "white",
              }}
            >
              <img src={nine} alt="one" />
              <Box>
                <Typography variant="h6" fontWeight="bold">
                  What comes next
                </Typography>
                <Typography variant="subtitle1" mt={1}>
                  At the final stage of our test automation consulting services,
                  you can choose to proceed with any of the three engagement
                  models:
                </Typography>
                <Container>
                  <Typography variant="subtitle1" mt={1}>
                    1. The a1qa team takes full control over your testing
                    automation and trains your staff to operate QA automation
                    tools.
                  </Typography>
                  <Typography variant="subtitle1" mt={1}>
                    2. After you set up your automated testing workflow, we
                    oversee its performance and mitigate any arising issues.
                  </Typography>
                  <Typography variant="subtitle1" mt={1}>
                    3. Our testing engineers augment your in-house team and
                    share the automation implementation workload the way you see
                    fit.
                  </Typography>
                </Container>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15} mb={5}>
          <Typography variant="h4" mb={5}>
            Break new ground in automated testing
          </Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              We integrate codeless testing into automated QA workflows, setting
              up test scenarios that require little to no coding. We document
              small-volume test cases, make them reusable, and assign each with
              a corresponding keyword, allowing you to create a viable testing
              case with just a sequence of relevant keywords.
            </Typography>
          </Container>
        </Box>
        {picBreak ? (
          <img src={pic} alt="" width={380} />
        ) : (
          <img src={pics} alt="" width={padd ? 900 : 1300} />
        )}
      </Container>
      <Container sx={{ marginBottom: 16 }}>
        <Box textAlign="center" mt={15}>
          <Typography variant="h4">The outcomes of test automation</Typography>
          <Container>
            <Typography variant="subtitle1" mt={3}>
              Through our expert-level test automation services, we not only
              help enterprises achieve their immediate objectives but also aid
              in accomplishing such comprehensive business and operational goals
              as:
            </Typography>
          </Container>
        </Box>
        <Grid container spacing={5} mt={5} textAlign="center">
          <Grid item xs={12} md={4} lg={4}>
            <Box mb={3}>
              <img src={ten} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Evaluate your readiness for a TCoE
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box mb={3}>
              <img src={eleven} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Flexibility in handling any ad-hoc requests
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box mb={3}>
              <img src={twee} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              Proactive communication and detailed reporting
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box mb={3}>
              <img src={thir} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              A complete range of software testing competencies
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box mb={3}>
              <img src={fourt} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              A complete range of software testing competencies
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Box mb={3}>
              <img src={fift} alt="one" />
            </Box>
            <Typography variant="subtitle1">
              A complete range of software testing competencies
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Automation;
