import { Box, Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        scrollBehavior: "unset",
      }}
    >
      <Box
        height="100%"
        display="flex"
        flexGrow={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" gutterBottom align="center">
          Oops Sorry... Page Not Found | Error: 404
        </Typography>
      </Box>
    </Box>
  );
};

export default NotFound;
