import React, { useState } from "react";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Fab, useMediaQuery } from "@mui/material";

const ScrollButton = () => {
  const padd = useMediaQuery("(max-width:1260px)");
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;

    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Box
      role="presentation"
      sx={{
        position: "fixed",
        width: "100%",
        left: padd ? "85%" : "93%",
        bottom: "50px",
        height: "50px",
        fontSize: "3rem",
        zIndex: 1,
        cursor: "pointer",
        display: visible ? "inline" : "none",
      }}
    >
      <Fab
        onClick={scrollToTop}
        color="primary"
        size="medium"
        aria-label="scroll back to top"
      >
        <KeyboardArrowUpIcon style={{ fontSize: 40 }} />
      </Fab>
    </Box>
  );
};

export default ScrollButton;
